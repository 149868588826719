import { FC, useEffect, useRef, useState } from 'react'
import { Box } from '@fower/react'
import { Button, Dialog, DialogContent, IconButton, TextField } from '@mui/material'
import {
    OriginalProductItemVO,
    ShippingMethodOption,
    Volume,
    ZeroVolume,
} from '../TypeDeclare'
import EditableTextArea from './EditableTextArea'
import NumericInput from 'react-numeric-input'
import { useProductStore } from '../pages/product_list/store/useProdustListStore'
import { Close } from '@mui/icons-material'
import { observer } from 'mobx-react-lite'
import ShippingInfo from '../pages/dashboard/upload_product/components/ShippingInfo'
import RawProductImageReEdit from './RawProductImageReEdit'
import ImageUploader from '../pages/dashboard/upload_product/components/ImageUploader'
import AddIcon from '@mui/icons-material/Add';
import PsdUploaderDialog from '../pages/dashboard/upload_product/components/PsdUploaderDialog'
import QuillEditor from '../pages/dashboard/upload_product/components/Editor'


export type SetRawProductPropsModalProps = {
    product?: OriginalProductItemVO
    isOpen: boolean
    hasImage?: boolean
    uploading: boolean
    onSubmit: (
        title: string,
        price: number,
        shippingOptions: ShippingMethodOption[],
        weight: number,
        volume: Volume,
        desc: string,
        updatedCoverImage: Array<File | string>,
        updatedEditableImage: Array<File | string>,
        updatedLayerImage: any,
        updatedMaskImage: any,
        psd_sku: string[],
        deleteImages: {
            action: string,
            id: string | number
        }[],
        descriptionHtml: string
    ) => void
    onClose: () => void
    onUpdateShippingOption: (item: ShippingMethodOption, to: string) => void
}
const SetRawProductPropsModal: FC<SetRawProductPropsModalProps> = observer(
    ({ isOpen, hasImage = true, product, onSubmit, onClose, uploading }) => {

        const store = useProductStore()
        const [title, setTitle] = useState<string>(product?.name || '')
        const [desc, setDesc] = useState<string>(product?.descShorten || '')
        const [descriptionHtml, setDescriptionHtml] = useState<string>(product?.description_html || '')
        const [price, setPrice] = useState<number>(Number(product?.pairPrice))
        const [weight, setWeight] = useState<number>(0)
        const [volume, setVolume] = useState<Volume>(ZeroVolume)
        const updatedCoverImage = useRef<Array<File | string>>([])
        const updatedEditableImage = useRef<Array<File | string>>([])
        const updatedLayerImage = useRef<any>([])
        const updatedMaskImage = useRef<any>([])
        const [formItemList, setFormItemList] = useState<any>([])
        const [maskItemList, setMaskItemList] = useState<any>([])
        const deleteImages = useRef<{
            action: string,
            id: string | number
        }[]>([])
        const [psdOpen, setPsdOpen] = useState<boolean>(false)
        const currentDataIndex = useRef(0)


        function getSize(str: string) {
            const result = str.replace(/[a-zA-Z]+\s*/g, '');
            return result
        }

        function getLayer(str: string) {
            const englishWords = str.match(/[a-zA-Z]+/g);  // 获取所有英文字符，不包括空格
            return englishWords?.join(' ')
        }

        useEffect(() => {
            if (product) {

                let list: any[] = []
                let maskList: any[] = []
                const { psdSku, psdLayerImages, psdClippingMasks } = product
                psdLayerImages.forEach((layer, index) => {
                    list.push({
                        psd_sku: psdSku[index],
                        layer: layer,
                    })


                })
                psdClippingMasks.forEach((mask, index) => {

                    maskList.push({
                        ...mask,
                        image: psdClippingMasks[index].image,
                        comment: getLayer(psdClippingMasks[index].comment.substr(18)),
                        productSize: getSize(psdClippingMasks[index].comment.substr(18)).split(' ')[0].split('*'),
                        actualSize: getSize(psdClippingMasks[index].comment.substr(18)).split(' ')[1].split('*')
                    })
                })

                setMaskItemList(maskList)
                setFormItemList(list)
                setTitle(product.name)
                setPrice(Number(product.pairPrice))
                setWeight(product.weight)
                setVolume(product.volume)
                setDesc(product.descShorten)
                setDescriptionHtml(product.description_html)
                updatedCoverImage.current = product.coverImages
                updatedEditableImage.current = product.images
                // @ts-ignore
                updatedLayerImage.current = [...product.psdLayerImages]
                // @ts-ignore
                updatedMaskImage.current = product.psdClippingMasks.map(item => {
                    return {
                        ...item,
                        comment: getLayer(item.comment.substr(18)),
                        productSize: getSize(item.comment.substr(18)).split(' ')[0].split('*'),
                        actualSize: getSize(item.comment.substr(18)).split(' ')[1].split('*')
                    }
                })
            }
        }, [store, product])
        if (!product) {
            return <></>
        }

        const getSkuHandle = (data: { sku: string, layerName: string }) => {

            updatedLayerImage.current[currentDataIndex.current].psd_sku = data.sku
            updatedLayerImage.current[currentDataIndex.current].comment = `psd layer ${data.layerName}`
            // updatedMaskImage.current[currentDataIndex.current].comment = `psd clipping mask ${data.layerName}`


            setFormItemList(
                formItemList.map((item: { layer: { image: any }; mask: { image: any } }, i: number) =>
                    i === currentDataIndex.current ? {
                        ...item,
                        psd_sku: data.sku,
                        layer: {
                            image: item.layer.image,
                            comment: `psd layer ${data.layerName}` // layer.comment 是基于 layerName 的
                        },

                    } : item
                )
            );

        }


        const handleMaskSelected = (mask: File, index: number) => {

            updatedMaskImage.current = updatedMaskImage.current.map((item: any, i: number) =>
                i === index ? { ...item, image: mask } : item
            )
            setMaskItemList(
                maskItemList.map((item: { image: any }, i: number) =>
                    i === index ? { ...item, image: mask } : item
                )
            );

        }

        const handleLayerSelected = (layer: File, index: number) => {
            //@ts-ignore
            updatedLayerImage.current = updatedLayerImage.current.map((item: { layer: any }, i: number) =>
                i === index ? { ...item, image: layer } : item
            )

            setFormItemList(
                formItemList.map((item: { layer: any }, i: number) =>
                    i === index ? { ...item, layer: { ...item.layer, image: layer } } : item
                )
            );

            // setChanged(true)
        }

        const deleteMask = (image: File, index: number) => {
            deleteImages.current.push({
                action: 'delete',
                id: updatedMaskImage.current[index].id
            })
            updatedMaskImage.current = updatedMaskImage.current.map((item: any, i: number) =>
                i === index ? { ...item, image: null } : item
            )

            setMaskItemList((pre: any) => {

                return pre.map((item: { mask: any }, i: number) =>
                    i === index ? { ...item, image: null } : item
                )
            })
            // setChanged(true)
        }

        const deleteMaskItem = (item: any, index: number) => {

            if (item.id) {
                deleteImages.current.push({
                    action: 'delete',
                    id: item.id
                })
            }

            setMaskItemList((pre: any[]) => {
                const newPre = pre.filter((v, i) => {

                    return i !== index
                })

                return newPre
            })

        }

        const deleteItem = (item: any, index: number) => {

            if (item.layer.id) {
                deleteImages.current.push({
                    action: 'delete',
                    id: item.layer.id
                })
            }

            setFormItemList(
                formItemList.filter((item: any, i: number) => {
                    return i !== currentDataIndex.current
                })
            );
        }

        const deleteLayer = (image: File, index: number) => {
            deleteImages.current.push({
                action: 'delete',
                id: updatedLayerImage.current[index].id
            })
            updatedLayerImage.current = updatedLayerImage.current.map((item: any, i: number) =>
                i === index ? { ...item, image: null } : item
            )

            setFormItemList((pre: any) => {

                return pre.map((item: { layer: any }, i: number) =>
                    i === index ? { ...item, layer: { ...item.layer, image: null } } : item
                )
            })
            // opStore.formItemList[index].layer.image = null
            // opStore.setFormItemList(
            //     opStore.formItemList.map((item, i) =>
            //         i === index ? { ...item, layer: { ...item.layer, image: null } } : item
            //     )
            // );
            // setChanged(true)
        }

        const getMaskImagesView = (index: number) => {
            // if (!formItemList[index].mask.image) {
            //     return (
            //         <ImageUploader
            //             key={'no-mask-file1'}
            //             image={undefined}
            //             onSelect={(selectedImage) => handleMaskSelected(selectedImage, index)}
            //             onDelete={(selectedImage) => {
            //                 deleteMask(selectedImage, index)
            //             }}
            //         />
            //     )
            // }

            return (
                <ImageUploader
                    key={`maskImageFile-${index}`}
                    image={maskItemList[index].image as File}
                    onSelect={(selectedImage) => handleMaskSelected(selectedImage, index)}
                    onDelete={(selectedImage) => {
                        deleteMask(selectedImage, index)
                    }}
                />
            )
        }

        const getPsdView = (index: number) => {

            return <>
                <Box>{formItemList[index].psd_sku}</Box>
                <Button onClick={() => {
                    currentDataIndex.current = index
                    setPsdOpen(true)
                }}>upload psd</Button>

            </>
        }
        const getLayerImagesView = (index: number) => {
            if (!formItemList[index].layer) {
                return (
                    <ImageUploader
                        key={'no-layer-file'}
                        image={undefined}
                        onSelect={(selectedImage) => handleLayerSelected(selectedImage, index)}
                        onDelete={(selectedImage) => {
                            deleteLayer(selectedImage, index)
                        }}
                    />

                )
            }

            return (
                <ImageUploader
                    key={`layerImageFile-${index}`}
                    image={formItemList[index].layer.image as File}
                    onSelect={(selectedImage) => handleLayerSelected(selectedImage, index)}
                    onDelete={(selectedImage) => {
                        deleteLayer(selectedImage, index)
                    }}
                />
            )
        }

        return (
            <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
                <Box row toCenterY mt3 ml5 toRight>
                    <IconButton
                        onClick={onClose}
                        sx={{
                            mr: 1,
                        }}
                    >
                        <Close />
                    </IconButton>
                </Box>
                <DialogContent>
                    <Box w-100p row toTop>
                        {hasImage && (
                            <Box column w="400px" h-100p>
                                <RawProductImageReEdit
                                    product={product}
                                    isOpen={isOpen}
                                    onUpdate={(images, type) => {
                                        if (type === 'cover') {
                                            updatedCoverImage.current = images
                                        }
                                        if (type === 'editable') {
                                            updatedEditableImage.current = images
                                        }
                                    }}
                                />
                            </Box>
                        )}
                        <Box column w-60p={hasImage} w-100p={!hasImage} h-100p p3 spaceY3>
                            <EditableTextArea
                                text={title}
                                label="Change title"
                                onUpdate={(newValue) => {
                                    setTitle(newValue)
                                }}
                                immediateUpdate={true}
                            />
                            <Box fontExtrabold mr2 black>
                                Set Price
                            </Box>
                            <NumericInput
                                step={0.1}
                                value={price}
                                precision={2}
                                snap
                                strict
                                onChange={(value) => {
                                    if (value !== null) {
                                        setPrice(value)
                                    }
                                }}
                                style={{
                                    input: {
                                        padding: '10px',
                                    },
                                }}
                            />
                            <EditableTextArea
                                text={desc}
                                label="Change Desc"
                                onUpdate={(newValue) => {
                                    setDesc(newValue)
                                }}
                                immediateUpdate={true}
                            />
                            <QuillEditor
                                html={descriptionHtml}
                                onGetHtml={(data) => setDescriptionHtml(data)}
                            />
                            <ShippingInfo
                                previewMode={false}
                                weight={weight}
                                volume={volume}
                                onWeightChange={(value) => {
                                    setWeight(value)
                                }}
                                onVolumeChange={(value, field) => {
                                    setVolume((prev) => {
                                        return { ...prev, [field]: value }
                                    })
                                }}
                            />

                            <Button
                                variant="contained"
                                size="large"
                                color="secondary"
                                sx={{ m: 2 }}
                                disabled={uploading}
                                onClick={() => {
                                    updatedMaskImage.current.forEach((item: { comment: string; productSize: string[]; actualSize: string[] }) => {
                                        item.comment = item.comment + ' ' + item.productSize[0] + '*' + item.productSize[1] + ' ' + item.actualSize[0] + '*' + item.actualSize[1]
                                    })
                                    onSubmit(
                                        title,
                                        price,
                                        product.shippingOptions,
                                        weight,
                                        volume,
                                        desc,
                                        updatedCoverImage.current,
                                        updatedEditableImage.current,
                                        updatedLayerImage.current,
                                        updatedMaskImage.current,
                                        formItemList.map((item: { psd_sku: any }) => item.psd_sku),
                                        deleteImages.current,
                                        descriptionHtml
                                    )
                                }}
                            >
                                {uploading ? 'Updating' : 'Save'}
                            </Button>
                        </Box>
                    </Box>
                    <Box w-100p column toTop>
                        <Box textXL fontBold flex justifyContent="space-between" alignItems="center">
                            Add PSD and Layer diagram*

                            <IconButton aria-label="add" color="primary" onClick={() => {
                                setFormItemList((pre: { psd_sku: string; layer: {}; }[]) => {
                                    pre.push({
                                        psd_sku: '',
                                        layer: {
                                            image: null,
                                            comment: ``,
                                            actualSize: [0, 0],
                                            productSize: [0, 0]
                                        },
                                    })
                                    return [...pre]
                                })

                                updatedLayerImage.current.push({
                                    psd_sku: '',
                                    layer: {
                                        image: null,
                                        comment: ``,
                                        actualSize: [0, 0],
                                        productSize: [0, 0]
                                    },
                                })
                            }}>
                                <AddIcon />
                            </IconButton>
                        </Box>
                        <Box textSM fontLight mt1>
                            Up to 30 Images
                        </Box>

                        {
                            formItemList.map((item: any, index: number) => {
                                return (
                                    <Box flex flexWrap alignItems="center" key={index}>
                                        <Box row h="100%" w="25%" overflowXAuto flexWrap>
                                            {getPsdView(index)}
                                        </Box>
                                        <Box row h="100%" w="30%">
                                            {getLayerImagesView(index)}
                                        </Box>
                                        <Box row h="100%" w="30%">
                                            <Button variant="text" onClick={() => deleteItem(item, index)}>delete</Button>
                                        </Box>
                                    </Box>
                                )
                            })
                        }
                    </Box>

                    <Box w-100p column toTop>
                        <Box textXL fontBold flex justifyContent="space-between" alignItems="center">
                            Add PSD and MaskImage*

                            <IconButton aria-label="add" color="primary" onClick={() => {
                                setMaskItemList((pre: {}[]) => {
                                    pre.push({
                                        image: null,
                                        comment: ``,
                                        actualSize: [0, 0],
                                        productSize: [0, 0]
                                    })
                                    return [...pre]
                                })
                                updatedMaskImage.current.push({
                                    image: null,
                                    comment: ``,
                                    actualSize: [0, 0],
                                    productSize: [0, 0]
                                })


                            }}>
                                <AddIcon />
                            </IconButton>
                        </Box>
                        <Box textSM fontLight mt1>
                            Up to 30 Images
                        </Box>

                        {
                            maskItemList.map((item: any, index: number) => {
                                return (
                                    <Box flex flexWrap key={index + 'mask'} alignItems="center">


                                        <Box row h="100%" w="20%">
                                            {getMaskImagesView(index)}
                                        </Box>
                                        <Box row h="100%" w="15%" flex alignItems="center">
                                            <TextField label="layer Name" variant="outlined"
                                                value={item.comment}
                                                onChange={(e) => {
                                                    setMaskItemList(maskItemList.map((v: any, i: number) => {
                                                        return i === index ? { ...v, comment: e.target.value } : v
                                                    }))
                                                    updatedMaskImage.current = updatedMaskImage.current.map((v: any, i: number) =>
                                                        i === index ? { ...v, comment: e.target.value } : v
                                                    )
                                                }} />
                                        </Box>
                                        <Box row h="100%" w="25%" flex alignItems="center" ml5>
                                            <TextField label="Production height" variant="outlined"
                                                value={item.productSize[0]}
                                                type="number"
                                                onChange={(e) => {

                                                    setMaskItemList(maskItemList.map((v: { productSize: any[] }, i: number) => {
                                                        return i === index ? { ...v, productSize: [Number(e.target.value), v.productSize[1]] } : v
                                                    }))
                                                    updatedMaskImage.current = updatedMaskImage.current.map((v: any, i: number) =>
                                                        i === index ? { ...v, productSize: [Number(e.target.value), v.productSize[1]] } : v
                                                    )

                                                }} />
                                            <span style={{ fontSize: '24px' }}>*</span>
                                            <TextField label="Production width" variant="outlined"
                                                value={item.productSize[1]}
                                                type="number"
                                                onChange={(e) => {
                                                    setMaskItemList(maskItemList.map((v: { productSize: any[] }, i: number) => {
                                                        return i === index ? { ...v, productSize: [v.productSize[0], Number(e.target.value)] } : v
                                                    }))
                                                    updatedMaskImage.current = updatedMaskImage.current.map((v: any, i: number) =>
                                                        i === index ? { ...v, productSize: [v.productSize[0], Number(e.target.value)] } : v
                                                    )
                                                }} />
                                        </Box>
                                        <Box row h="100%" w="25%" flex alignItems="center" ml5>
                                            <TextField label="Actual height" variant="outlined"
                                                value={item.actualSize[0]}
                                                type="number"
                                                onChange={(e) => {
                                                    setMaskItemList(maskItemList.map((v: { actualSize: any[] }, i: number) => {
                                                        return i === index ? { ...v, actualSize: [Number(e.target.value), v.actualSize[1]] } : v
                                                    }))
                                                    updatedMaskImage.current = updatedMaskImage.current.map((v: any, i: number) =>
                                                        i === index ? { ...v, actualSize: [Number(e.target.value), v.actualSize[1]] } : v
                                                    )
                                                }} />
                                            <span style={{ fontSize: '24px' }}>*</span>
                                            <TextField label="Actual width" variant="outlined"
                                                value={item.actualSize[1]}
                                                type="number"
                                                onChange={(e) => {
                                                    setMaskItemList(maskItemList.map((v: { actualSize: any[] }, i: number) => {
                                                        return i === index ? { ...v, actualSize: [v.actualSize[0], Number(e.target.value)] } : v
                                                    }))
                                                    updatedMaskImage.current = updatedMaskImage.current.map((v: any, i: number) =>
                                                        i === index ? { ...v, actualSize: [v.actualSize[0], Number(e.target.value)] } : v
                                                    )
                                                }} />
                                        </Box>
                                        <Box row h="100%" >
                                            <Button variant="text" onClick={() => deleteMaskItem(item, index)}>delete</Button>
                                        </Box>
                                    </Box>
                                )
                            })
                        }
                    </Box>
                </DialogContent>

                {psdOpen && <PsdUploaderDialog getSkuHandle={getSkuHandle} psdOpen={psdOpen} handlePsdDialogClose={() => setPsdOpen(false)} />}
            </Dialog >
        )
    },
)
export default SetRawProductPropsModal
