import { FC } from 'react'
import { Box } from '@fower/react'
import { StyledImage } from '../../../TypeDeclare'
// import { Popover } from '@mui/material'
import { useAuthStore } from '../../../store/useAuthStore'

export type DashBoardHomeProps = {
    onToConnect: () => void
    onToCreate: () => void
}

const DashBoardHome: FC<DashBoardHomeProps> = ({ onToConnect, onToCreate }) => {
    // const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    const { user } = useAuthStore()
    // const handleClose = () => {
    //   setAnchorEl(null)
    // }
    // const open = Boolean(anchorEl)
    // const id = open ? 'simple-popover' : undefined

    return (
        <Box
            w-100p
            h="calc(100vh - 72px)"
            column
            toCenterX
        >
            <Box w="800px" toCenter column spaceY8 mt5>
                <StyledImage src="/images/dashboard-head.png" alt="logo" w-100p />
                <Box w-100p text3XL fontBold>
                    Welcome {user?.email.split('@')[0]}
                </Box>
                <Box w-100p textXL>
                    We're excited to have you on board!
                </Box>
                <Box
                    w-100p
                    textXL
                    style={{
                        lineHeight: '24px',
                    }}
                >
                    Take the next steps! Set up your store, design products, and start making money with Custom Tee. Complete the following three steps to unlock 20% rebates and up to $1000 in bonuses.
                </Box>
                <Box w-100p spaceY5>
                    <Box
                        rounded
                        w-100p
                        p6
                        bgWhite
                        shadowLarge
                        border
                        borderGray100
                        row
                        toCenterY
                        toBetween
                    >
                        <Box circle6 toCenter border-2 borderGray600 textSM>
                            1
                        </Box>
                        <Box w-50p toLeft>
                            Connect your Shopify store with just a few clicks
                        </Box>
                        <Box
                            white
                            bg={'#4384D6'}
                            rounded
                            p3
                            toCenter
                            w-20p
                            onClick={onToConnect}
                        >
                            Find more
                        </Box>
                    </Box>
                    <Box
                        rounded
                        w-100p
                        p6
                        bgWhite
                        shadowLarge
                        border
                        borderGray100
                        row
                        toCenterY
                        toBetween
                    >
                        <Box circle6 toCenter border-2 borderGray600 textSM>
                            2
                        </Box>
                        <Box w-50p toLeft>
                            Add your first product
                        </Box>
                        <Box
                            white
                            bg={'#4384D6'}
                            rounded
                            p3
                            toCenter
                            w-20p
                            onClick={onToCreate}
                        >
                            Happy designing
                        </Box>
                    </Box>
                    <Box
                        rounded
                        w-100p
                        p6
                        bgWhite
                        shadowLarge
                        border
                        borderGray100
                        row
                        toCenterY
                        toBetween
                    >
                        <Box circle6 toCenter border-2 borderGray600 textSM>
                            3
                        </Box>
                        <Box w-50p toLeft>
                            Start making money
                        </Box>
                        <Box rounded p3 toCenter w-20p></Box>
                    </Box>
                </Box>
            </Box>
            {/* <Box
        aria-describedby={id}
        onClick={(event: {
          currentTarget: SetStateAction<HTMLElement | null>
        }) => {
          setAnchorEl(event.currentTarget)
        }}
        circle16
        bg="#2F2F2F"
        fixed
        right5
        bottom5
        white
        toCenter
        text2XL
      >
        ?
      </Box> */}
            {/* <Popover
        anchorEl={anchorEl}
        id={id}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Box
          w="320px"
          p6
          white
          style={{
            backgroundColor: '#2F2F2F',
            lineHeight:'24px'
          }}
        >
          Any questions? Shoot an email to hello@customtee.co , Our dedicated
          team is available 24/7 to provide assistance.
        </Box>
      </Popover> */}
        </Box>
    )
}
export default DashBoardHome
