import { Box } from '@fower/react'
import { Drawer } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FeaturedProductListItem, ProductItemVO } from '../../TypeDeclare'
import DetailedBar from '../product_list/DetailedBar'
import { useProductStore } from '../product_list/store/useProdustListStore'
import styles from './LandingSection.module.css'
import FeaturedList from './FeaturedList'

const FeaturedProducts: FC = observer(() => {
    const store = useProductStore()
    const navigator = useNavigate()
    const [selectedItem, setSelectedItem] = useState<ProductItemVO | undefined>(
        undefined,
    )
    const [showDetailedBar, setShowDetailedBar] = useState<boolean>(false)
    const [featuredList, setFeaturedList] = useState<
        Array<FeaturedProductListItem>
    >([])
    useEffect(() => {
        store.clearEditorData()
        // store.reloadDC()
        store.reloadFeaturedProducts().then((res) => {
            setFeaturedList(res)
        })
    }, [store])
    return (
        <Box w--lg="80%" w="100%" column toCenter>
            {featuredList.map((feature) => {
                return (
                    <FeaturedList
                        key={feature.name}
                        feature={feature}
                        onSelect={(item) => {
                            store.setProduct(item)
                            setSelectedItem(store.product)
                            //   setShowDetailedBar(true)
                            const { name, id } = item
                            const newName = name.toLocaleLowerCase().split(' ').join('-')
                            // navigator(`/detail?productId=${item.id}&data=${selectedItem ? JSON.stringify(selectedItem) : ''}`)
                            navigator(`/detail/${id}/${newName}`)
                        }}
                    />
                )
            })}
            <Box
                className={styles.developerChoiceShowMore}
                bgGray100
                rounded
                p2
                pr5
                pl5
                cursorPointer
                mb10
                onClick={() => {
                    navigator('/catalog/all')
                }}
            >
                Show more
            </Box>
            <Drawer
                anchor="right"
                open={showDetailedBar}
                onClose={() => {
                    setShowDetailedBar(false)
                    setSelectedItem(undefined)
                }}
            >
                <DetailedBar
                    data={selectedItem}
                    sizeTableData={store.sizeTableList}
                    onClose={() => {
                        setShowDetailedBar(false)
                        setSelectedItem(undefined)
                    }}
                />
            </Drawer>
        </Box>
    )
})
export default FeaturedProducts
