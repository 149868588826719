import { Box } from '@fower/react'
import { Button } from '@mui/material'
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import { FC, useEffect, useRef, useState } from 'react'
import ImageUploader from './components/ImageUploader'
import { useOriginalProductStore } from '../store/useOriginalProductStore'
import { observer } from 'mobx-react-lite'
import SimpleUploading from './components/SimpleUploading'

import PsdUploaderDialog from './components/PsdUploaderDialog';
// TODO 点击Upload psd弹出弹窗与foxpsd上传的弹窗一样，上传完毕关闭弹窗然后显示psd接口返回的封面图片
type UploadImageProps = {
    onNext: () => void
    onBack: () => void
}
const EDITABLE_UP_TO = 1
const COVER_UP_TO = 7
const SIZETABLE_UP_TO = 3
const LAYER_UP_TO = 29

const UploadImage: FC<UploadImageProps> = observer(({ onNext, onBack }) => {
    const opStore = useOriginalProductStore()
    const [changed, setChanged] = useState<boolean>(false)
    const [canUpload, setCanUpload] = useState<boolean>(false)
    const [psdOpen, setPsdOpen] = useState<boolean>(false)
    const [uploaded, setUploaded] = useState<boolean>(false)
    const [uploading, setUploading] = useState<boolean>(false)
    let currentDataIndex = useRef<any>(null)

    useEffect(() => {
        const layerFlag = opStore.formItemList.length > 0 && opStore.formItemList.every(cif => cif.layer.image)
        const maskFlag = opStore.maskItemList.length > 0 && opStore.maskItemList.every(cif => cif.mask.image)
        const skuFlag = opStore.formItemList.length > 0 && opStore.formItemList.every(cif => cif.psdSku)

        setCanUpload(
            opStore.coverImageFiles.filter((cif) => cif !== undefined).length > 0 &&
            opStore.editableImageFiles.filter((eif) => eif !== undefined).length >
            0
            &&
            layerFlag &&
            maskFlag
            &&
            skuFlag
        )
    }, [opStore.coverImageFiles, opStore.editableImageFiles, opStore.formItemList, opStore.maskItemList])

    useEffect(() => {
        setUploaded(
            opStore.coverImages.length > 0 && opStore.editableImages.length > 0,
        )
    }, [opStore.coverImages.length, opStore.editableImages.length])

    const handlePsdDialogClose = () => {
        setPsdOpen(false)
    }

    const getSkuHandle = (data: { sku: string, layerName: string }) => {

        opStore.setFormItemList(
            opStore.formItemList.map((item, i) =>
                i === currentDataIndex.current ? {
                    ...item,
                    psdSku: data.sku,
                    layer: {
                        image: item.layer.image,
                        comment: `psd layer ${data.layerName}` // layer.comment 是基于 layerName 的
                    },
                } : item
            )
        );

    }

    const uploadImageSelected = async () => {
        setUploading(true)
        const coverResult = await opStore.uploadImages(
            opStore.coverImageFiles,
            (_) => { },
        )
        const editableResult = await opStore.uploadImages(
            opStore.editableImageFiles,
            (_) => { },
        )
        if (opStore.sizeTableImageFiles.length > 0) {
            const sizeTableResult = await opStore.uploadImages(
                opStore.sizeTableImageFiles,
                (_) => { },
            )
            const sizeTable = sizeTableResult.map((r) => r.url!)
            opStore.setSizeTableImages(sizeTable)
        }

        if (opStore.maskItemList.length > 0) {
            const maskImages = opStore.maskItemList.map((item) => {
                return item.mask.image
            })

            const maskResult = await opStore.uploadImages(
                maskImages as File[],
                (_) => { },
            )

            const mask = maskResult.map((r) => r.url!)

            opStore.setMaskImageFiles(mask)
        }

        if (opStore.formItemList.length > 0) {
            const layerImages = opStore.formItemList.map((item) => {
                return item.layer.image
            })

            const layerResult = await opStore.uploadImages(
                layerImages as File[],
                (_) => { },
            )

            const layer = layerResult.map((r) => r.url!)

            opStore.setLayerImageFiles(layer)

        }

        const cover = coverResult.map((r) => r.url!)
        opStore.setCoverImages(cover)
        const editables = editableResult.map((r) => r.url || '')

        opStore.setEditableImages(editables)
        setUploading(false)
        setChanged(false)
    }
    const canGoNext = canUpload && changed

    const handleMaskSelected = (mask: File, index: number) => {

        opStore.setMaskItemList(
            opStore.maskItemList.map((item, i) =>
                i === index ? { ...item, mask: { ...item.mask, image: mask } } : item
            )
        );

        setChanged(true)
    }

    const handleLayerSelected = (layer: File, index: number) => {

        // const newEIs = [
        //     ...opStore.layerImageFiles.filter((file) => file !== undefined),
        //     layer,
        // ]

        // if (opStore.layerImageFiles.some((newEI) => newEI?.name === layer.name)) {
        //     alert('Cannot select same images')
        // }
        // const distinctEIs = newEIs.filter((value, index, self) => {
        //     return self.findIndex((v) => v?.name === value?.name) === index
        // })

        // if (distinctEIs.length <= 1) {
        //     distinctEIs.push(undefined)
        // }
        opStore.setFormItemList(
            opStore.formItemList.map((item, i) =>
                i === index ? { ...item, layer: { ...item.layer, image: layer } } : item
            )
        );

        // opStore.setLayerImageFiles(distinctEIs)
        setChanged(true)
    }

    const handleCoverSelected = (cover: File) => {
        const newEIs = [
            ...opStore.coverImageFiles.filter((file) => file !== undefined),
            cover,
        ]
        if (opStore.coverImageFiles.some((newEI) => newEI?.name === cover.name)) {
            alert('Cannot select same images')
        }
        const distinctEIs = newEIs.filter((value, index, self) => {
            return self.findIndex((v) => v?.name === value?.name) === index
        })

        if (distinctEIs.length <= COVER_UP_TO) {
            distinctEIs.push(undefined)
        }
        opStore.setCoverImageFiles(distinctEIs)
        setChanged(true)
    }
    const handleSizeTableSelected = (sizeImage: File) => {
        const newEIs = [
            ...opStore.sizeTableImageFiles.filter((file) => file !== undefined),
            sizeImage,
        ]
        if (
            opStore.sizeTableImageFiles.some(
                (newEI) => newEI?.name === sizeImage.name,
            )
        ) {
            alert('Cannot select same images')
        }
        const distinctEIs = newEIs.filter((value, index, self) => {
            return self.findIndex((v) => v?.name === value?.name) === index
        })

        if (distinctEIs.length <= SIZETABLE_UP_TO) {
            distinctEIs.push(undefined)
        }
        opStore.setSizeTableImageFiles(distinctEIs)
        setChanged(true)
    }
    const handleEditableImageSelected = (image: File) => {
        const newEIs = [
            ...opStore.editableImageFiles.filter((file) => file !== undefined),
            image,
        ]
        if (
            opStore.editableImageFiles.some((newEI) => newEI?.name === image.name)
        ) {
            alert('Cannot select same images')
        }
        const distinctEIs = newEIs.filter((value, index, self) => {
            return self.findIndex((v) => v?.name === value?.name) === index
        })

        if (distinctEIs.length === EDITABLE_UP_TO) {
            distinctEIs.push(undefined)
        }
        opStore.setEditableImageFiles(distinctEIs)
        setChanged(true)
    }
    const deleteMask = (image: File, index: number) => {

        opStore.setMaskItemList(
            opStore.maskItemList.map((item, i) =>
                i === index ? { ...item, mask: { ...item.mask, image: null } } : item
            )
        );
        opStore.maskItemList[index].mask.image = null
        setChanged(true)
    }
    const deletePsd = (psd: File) => {
        // const newEIs = [
        //     ...opStore.psd_sku.filter((file) => {
        //         if (!file) return false
        //         return psd.name !== file.name
        //     }),
        // ]
        // if (newEIs.length <= LAYER_UP_TO) {
        //     newEIs.push(undefined)
        // }
        // opStore.setPsdSkuFiles(newEIs)
        setChanged(true)
    }
    const deleteLayer = (image: File, index: number) => {
        // const newEIs = [
        //     ...opStore.layerImageFiles.filter((file) => {
        //         if (!file) return false
        //         return image.name !== file.name
        //     }),
        // ]
        // if (newEIs.length <= LAYER_UP_TO) {
        //     newEIs.push(undefined)
        // }
        opStore.formItemList[index].layer.image = null
        opStore.setFormItemList(
            opStore.formItemList.map((item, i) =>
                i === index ? { ...item, layer: { ...item.layer, image: null } } : item
            )
        );
        setChanged(true)
    }
    const deleteCover = (image: File) => {
        const newEIs = [
            ...opStore.coverImageFiles.filter((file) => {
                if (!file) return false
                return image.name !== file.name
            }),
        ]
        if (newEIs.length <= COVER_UP_TO) {
            newEIs.push(undefined)
        }
        opStore.setCoverImageFiles(newEIs)
        setChanged(true)
    }
    const deleteSizeTable = (image: File) => {
        const newEIs = [
            ...opStore.sizeTableImageFiles.filter((file) => {
                if (!file) return false
                return image.name !== file.name
            }),
        ]
        if (newEIs.length <= SIZETABLE_UP_TO) {
            newEIs.push(undefined)
        }
        opStore.setSizeTableImageFiles(newEIs)
        setChanged(true)
    }
    const deleteEditableImage = (image: File) => {
        const newEIs = [
            ...opStore.editableImageFiles.filter((file) => {
                if (!file) return false
                return image.name !== file.name
            }),
        ]
        if (newEIs.length === EDITABLE_UP_TO) {
            newEIs.push(undefined)
        }
        opStore.setEditableImageFiles(newEIs)
        setChanged(true)
    }

    const getEditableImageView = () => {
        if (opStore.editableImageFiles.length === 0) {
            return (
                <ImageUploader
                    key={'nofile'}
                    image={undefined}
                    onSelect={(selectedImage) =>
                        handleEditableImageSelected(selectedImage)
                    }
                    onDelete={(selectedImage) => {
                        deleteEditableImage(selectedImage)
                    }}
                />
            )
        }

        return opStore.editableImageFiles.map((file, index) => {
            return (
                <ImageUploader
                    key={`file-${index}`}
                    image={file}
                    onSelect={(selectedImage) =>
                        handleEditableImageSelected(selectedImage)
                    }
                    onDelete={(selectedImage) => {
                        deleteEditableImage(selectedImage)
                    }}
                />
            )
        })
    }

    const getMaskImagesView = (index: number) => {


        return (
            <ImageUploader
                key={`maskImageFile-${index}`}
                image={opStore.maskItemList[index].mask.image as File}
                onSelect={(selectedImage) => handleMaskSelected(selectedImage, index)}
                onDelete={(selectedImage) => {
                    deleteMask(selectedImage, index)
                }}
            />
        )
    }

    const getPsdView = (index: number) => {

        // if (!opStore.formItemList[index].psdSku) {
        //     return (
        //         <Button onClick={() => {
        //             currentDataIndex.current = index
        //             setPsdOpen(true)
        //         }}>upload psd</Button>
        //     )
        // }

        return <Box flex column toCenter>
            <Box>{opStore.formItemList[index].psdSku}</Box>
            <Button onClick={() => {
                currentDataIndex.current = index
                setPsdOpen(true)
            }}>upload psd</Button>
        </Box>
    }
    const getLayerImagesView = (index: number) => {
        if (!opStore.formItemList[index].layer) {
            return (
                <ImageUploader
                    key={'no-layer-file'}
                    image={undefined}
                    onSelect={(selectedImage) => handleLayerSelected(selectedImage, index)}
                    onDelete={(selectedImage) => {
                        deleteLayer(selectedImage, index)
                    }}
                />

            )
        }

        return (
            <ImageUploader
                key={`layerImageFile-${index}`}
                image={opStore.formItemList[index].layer.image as File}
                onSelect={(selectedImage) => handleLayerSelected(selectedImage, index)}
                onDelete={(selectedImage) => {
                    deleteLayer(selectedImage, index)
                }}
            />
        )
    }

    const getCoverImagesView = () => {
        if (opStore.coverImageFiles.length === 0) {
            return (
                <ImageUploader
                    key={'no-cover-file'}
                    image={undefined}
                    onSelect={(selectedImage) => handleCoverSelected(selectedImage)}
                    onDelete={(selectedImage) => {
                        deleteCover(selectedImage)
                    }}
                />
            )
        }

        return opStore.coverImageFiles.map((file, index) => {
            return (
                <ImageUploader
                    key={`coverImageFile-${index}`}
                    image={file}
                    onSelect={(selectedImage) => handleCoverSelected(selectedImage)}
                    onDelete={(selectedImage) => {
                        deleteCover(selectedImage)
                    }}
                />
            )
        })
    }
    const getSizeTableImagesView = () => {
        if (opStore.sizeTableImageFiles.length === 0) {
            return (
                <ImageUploader
                    key={'no-sizetable-file'}
                    image={undefined}
                    onSelect={(selectedImage) => handleSizeTableSelected(selectedImage)}
                    onDelete={(selectedImage) => {
                        deleteSizeTable(selectedImage)
                    }}
                />
            )
        }

        return opStore.sizeTableImageFiles.map((file, index) => {
            return (
                <ImageUploader
                    key={`sizetableImageFile-${index}`}
                    image={file}
                    onSelect={(selectedImage) => handleSizeTableSelected(selectedImage)}
                    onDelete={(selectedImage) => {
                        deleteSizeTable(selectedImage)
                    }}
                />
            )
        })
    }

    function deleteItem(_item: any, index: number) {
        opStore.setFormItemList(opStore.formItemList.filter((v, i) => {
            return index !== i
        }));
    }

    function deleteMaskHandle(_item: any, index: number) {
        opStore.setMaskItemList(opStore.maskItemList.filter((v, i) => {
            return index !== i
        }))
    }

    return (
        <Box w="100%" px10 pt10 column position="relative">
            <Box w="100%" column toTop>
                <Box column pl4>
                    <Box textXL fontBold>
                        Add Cover*
                    </Box>
                    <Box textSM fontLight mt1>
                        Up to {COVER_UP_TO + 1} Images
                    </Box>
                </Box>

                <Box row h="100%" w="100%" overflowXAuto flexWrap>
                    {getCoverImagesView()}
                </Box>
            </Box>

            <Box borderBottom-1 borderDashed borderGray200 h="1px" w="96%" />
            <Box w="100%" mt6 row toTop>
                <Box w="30%">
                    <Box textXL fontBold w="100%" pl4>
                        Add Editable Image*
                        <Box textSM fontLight mt1>
                            (1 or 2 Images)
                        </Box>
                    </Box>
                    <Box row h="100%" w="100%" overflowXAuto>
                        {getEditableImageView()}
                    </Box>
                </Box>
                <Box borderRight-1 borderDashed borderGray200 h="95%" mx="10px" />
                <Box>
                    <Box textXL fontBold>
                        Add Size Table
                        <Box textSM fontLight mt1>
                            (Optional, up to {SIZETABLE_UP_TO + 1} Images)
                        </Box>
                    </Box>
                    <Box row h="100%" w="100%" overflowXAuto flexWrap>
                        {getSizeTableImagesView()}
                    </Box>
                </Box>
            </Box>

            <Box w="100%" column toTop>
                <Box column pl4>
                    <Box textXL fontBold flex justifyContent="space-between" alignItems="center">
                        Add PSD and Layer diagram

                        <IconButton aria-label="add" color="primary" onClick={() => {
                            if (opStore.formItemList.length === 30) return
                            const newFormItemList = [
                                ...opStore.formItemList,
                                {
                                    id: 1,
                                    psdSku: '',
                                    layer: {
                                        image: null,
                                        comment: ''
                                    }
                                }
                            ];
                            opStore.setFormItemList(newFormItemList);


                        }}>
                            <AddIcon />
                        </IconButton>
                    </Box>
                    <Box textSM fontLight mt1>
                        Up to {LAYER_UP_TO + 1} Images
                    </Box>
                </Box>

                {
                    opStore.formItemList.map((item, index) => {
                        return (
                            <Box flex flexWrap key={index}>
                                <Box row h="100%" w="25%" overflowXAuto flexWrap>
                                    {getPsdView(index)}
                                </Box>
                                <Box row h="100%" w="30%">
                                    {getLayerImagesView(index)}
                                </Box>
                                <Box row h="100%" w="30%">
                                    <Button variant="text" onClick={() => deleteItem(item, index)}>delete</Button>
                                </Box>
                            </Box>
                        )
                    })
                }

            </Box>

            <Box w="100%" column toTop>
                <Box column pl4>
                    <Box textXL fontBold flex justifyContent="space-between" alignItems="center">
                        Add MaskImage*

                        <IconButton aria-label="add" color="primary" onClick={() => {
                            if (opStore.maskItemList.length === 30) return
                            const newFormItemList = [
                                ...opStore.maskItemList,
                                {
                                    id: 1,
                                    mask: {
                                        image: null,
                                        comment: '',
                                        productSize: [0, 0],
                                        actualSize: [0, 0]
                                    }
                                }
                            ];
                            opStore.setMaskItemList(newFormItemList);


                        }}>
                            <AddIcon />
                        </IconButton>
                    </Box>
                    <Box textSM fontLight mt1>
                        Up to {LAYER_UP_TO + 1} Images
                    </Box>
                </Box>

                {
                    opStore.maskItemList.map((item, index) => {
                        return (
                            <Box flex flexWrap key={index}>

                                <Box row h="100%" w="15%">
                                    {getMaskImagesView(index)}

                                </Box>
                                <Box row h="100%" w="15%" flex alignItems="center">
                                    <TextField label="layer Name" variant="outlined"
                                        value={item.mask.comment}
                                        onChange={(e) => {
                                            opStore.setMaskItemList(opStore.maskItemList.map((v, i) => {
                                                return i === index ? { ...v, mask: { ...v.mask, comment: e.target.value } } : v
                                            }))

                                        }} />
                                </Box>

                                <Box row h="100%" w="25%" flex alignItems="center" ml5>
                                    <TextField label="Production height" variant="outlined"
                                        value={item.mask.productSize[0]}
                                        type="number"
                                        onChange={(e) => {
                                            opStore.setMaskItemList(opStore.maskItemList.map((v, i) => {
                                                return i === index ? { ...v, mask: { ...v.mask, productSize: [Number(e.target.value), v.mask.productSize[1]] } } : v
                                            }))

                                        }} />
                                    <span style={{ fontSize: '24px' }}>*</span>
                                    <TextField label="Production width" variant="outlined"
                                        value={item.mask.productSize[1]}
                                        type="number"
                                        onChange={(e) => {
                                            opStore.setMaskItemList(opStore.maskItemList.map((v, i) => {
                                                return i === index ? { ...v, mask: { ...v.mask, productSize: [v.mask.productSize[0], Number(e.target.value)] } } : v
                                            }))

                                        }} />
                                </Box>
                                <Box row h="100%" w="25%" flex alignItems="center" ml5>
                                    <TextField label="Actual height" variant="outlined"
                                        value={item.mask.actualSize[0]}
                                        type="number"
                                        onChange={(e) => {
                                            opStore.setMaskItemList(opStore.maskItemList.map((v, i) => {
                                                return i === index ? { ...v, mask: { ...v.mask, actualSize: [Number(e.target.value), v.mask.actualSize[1]] } } : v
                                            }))

                                        }} />
                                    <span style={{ fontSize: '24px' }}>*</span>
                                    <TextField label="Actual width" variant="outlined"
                                        value={item.mask.actualSize[1]}
                                        type="number"
                                        onChange={(e) => {
                                            opStore.setMaskItemList(opStore.maskItemList.map((v, i) => {
                                                return i === index ? { ...v, mask: { ...v.mask, actualSize: [v.mask.actualSize[0], Number(e.target.value)] } } : v
                                            }))

                                        }} />
                                </Box>

                                <Box row h="100%" flex alignItems="center" ml5>
                                    <Button variant="text" onClick={() => deleteMaskHandle(item, index)}>delete</Button>

                                </Box>

                            </Box>
                        )
                    })
                }

            </Box>



            <Box w="20%" column toCenterX toBottom pb8 fixed bottom0 right0>
                {changed && (
                    <Button
                        disabled={!canGoNext}
                        size="large"
                        variant="contained"
                        sx={{ width: '80%', height: 50 }}
                        onClick={async () => {
                            await uploadImageSelected()
                        }}
                    >
                        Upload Images
                    </Button>
                )}
                {!changed && uploaded && (
                    <Button
                        size="large"
                        variant="contained"
                        sx={{ width: '80%', height: 50, mb: 2 }}
                        onClick={onNext}
                    >
                        Next
                    </Button>
                )}
                {!changed && (
                    <Button
                        size="large"
                        variant="outlined"
                        sx={{ width: '80%', height: 50 }}
                        onClick={onBack}
                    >
                        Back
                    </Button>
                )}
            </Box>
            <SimpleUploading isOpen={uploading} text="Uploading images..." />

            {psdOpen && <PsdUploaderDialog getSkuHandle={getSkuHandle} psdOpen={psdOpen} handlePsdDialogClose={handlePsdDialogClose} />}
        </Box>
    )
})

export default UploadImage
