import { FC, useRef, useState } from 'react'
import { Box } from '@fower/react'
import { LoginMode, StyledImage } from '../TypeDeclare'
import { Button } from '@mui/material'
import { useAuthStore } from '../store/useAuthStore'
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';

export type LoginBoxProps = {
    formOnly?: boolean
    loginOnly?: boolean
    refreshAfter: boolean
    onClose?: () => void
    onLoggedin?: (success: boolean, errMsg?: string) => void
    onSignedUp?: (success: boolean, errMsg?: string) => void
    onContinue?: () => void
    preMode?: LoginMode
}
const LoginBox: FC<LoginBoxProps> = ({
    preMode,
    onClose,
    onLoggedin,
    onSignedUp,
    onContinue,
    refreshAfter,
    formOnly = false,
    loginOnly = false,
}) => {
    const authStore = useAuthStore()
    const modeTo = preMode || (loginOnly ? 'login' : 'signup')
    const [mode, setMode] = useState<LoginMode>(modeTo)
    const [showPassword, setShowPassword] = useState(false)
    const [showRePassword, setShowRePassword] = useState(false)
    const userNameRef = useRef()
    const userPwdRef = useRef()
    const userPwd2Ref = useRef()
    const onLogin = (userName: string, pwd: string) => {
        authStore.login(userName, pwd).then((res) => {
            if (!res.success) {
                onLoggedin && onLoggedin(false, res.error!)
            } else {
                if (refreshAfter) {
                    global.location.reload()
                } else {
                    onLoggedin && onLoggedin(true)
                }
            }
        })
    }
    const onSignUp = (userName: string, pwd: string, pwd2: string) => {
        authStore.signUp(userName, pwd, pwd2).then((res) => {
            onSignedUp && onSignedUp(res.success, res.error!)
            // if (!res.success) {
            //   onSignedUp && onSignedUp(false, res.error!)
            // } else {

            //   onClose && onClose()
            // }
        })
    }

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowRePassword = () => setShowRePassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleMouseUpPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    return (
        <Box row overflowHidden relative style={{ width: '100%' }}>
            <Box absolute right="10px" top="10px" zIndex="99" style={{ cursor: 'pointer' }}
                onClick={onClose}>
                <CloseIcon />
            </Box>

            {!formOnly && (
                <Box bg="#6E8FA0" column w={mode === 'signup' ? '500px' : '400px'} toCenter spaceY3>
                    {/* <StyledImage src="/icons/logo_macaw_light.png" alt="logo" w16 h16 /> */}

                    {
                        mode === 'signup' ? <StyledImage
                            style={{ width: '100%', height: '100%' }}
                            src="/images/sign.png"
                            alt="logo-text"

                        />
                            : <StyledImage
                                style={{ width: '100%', height: '100%' }}
                                src="/images/logo-left.png"
                                alt="logo-text"

                            />
                    }
                </Box>
            )}

            <Box w={mode === 'signup' ? '500px' : '500px'} column p10 bgWhite spaceY5 transitionAll relative>
                {/* {!loginOnly && !authStore.isLogin && (
                    <Box
                        absolute
                        top0
                        right0
                        white
                        style={{ background: '#518DD9' }}
                        p2
                        roundedBottomLeft-1rem
                        textXS
                        onClick={() => {
                            setMode((prev) => {
                                return prev === 'login' ? 'signup' : 'login'
                            })
                        }}
                        cursorPointer
                    >
                        {mode === 'login' ? 'Sign Up' : 'Log in'}
                    </Box>
                )} */}
                <Box toCenterY borderRightBlack>
                    <StyledImage src="/icons/logo_macaw.png" alt="logo" w="20px" h='20px' />
                    <StyledImage
                        src="/icons/logo_text.png"
                        alt="logo-text"
                        w="120px"
                        h="14px"

                        hidden
                        display--lg="block"
                    />
                </Box>
                {authStore.isLogin ? (
                    <Box toCenter h20 textXL>Logged In</Box>
                ) : (
                    <>
                        <Box color="#6196DE" style={{ marginBottom: '20px', fontSize: '22px' }} fontWeight="bold">
                            {mode === 'login' ? 'Welcome back' : 'Sign up and start earning for FREE'}
                        </Box>
                        {mode === 'signup' && <Box color="#7B7B7B" style={{ fontSize: '14px' }}>Unlock 20% Rebates and Earn Up to $1000 in Bonuses</Box>}

                        {/* <TextField
                            inputRef={userNameRef}
                            label="Email"
                            variant="outlined" /> */}
                        <Box style={{ marginBottom: '8px', fontSize: '14px' }}>Email</Box>
                        <FormControl variant="outlined">
                            <OutlinedInput
                                inputRef={userNameRef}
                                placeholder='Email'
                                aria-describedby="Email"
                            />
                        </FormControl>
                        {/* <Input
                            inputRef={userNameRef}
                            type="text"
                            placeholder="Email"
                        ></Input> */}
                        <Box style={{ marginBottom: '8px', fontSize: '14px' }}>Password</Box>
                        <FormControl variant="outlined">
                            <OutlinedInput
                                inputRef={userPwdRef}
                                type={showPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            onMouseUp={handleMouseUpPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                placeholder="Password"
                            />
                        </FormControl>


                        {/* <TextField
                            inputRef={userPwdRef}
                            label="Password"
                            variant="outlined" /> */}
                        {/* <Input
                            inputRef={userPwdRef}
                            type="password"
                            placeholder="Password"
                        ></Input> */}
                    </>
                )}
                {mode === 'signup' && (
                    <>
                        <Box style={{ marginBottom: '8px', fontSize: '14px' }}>Re-enter Password</Box>
                        <FormControl variant="outlined">
                            <OutlinedInput
                                inputRef={userPwd2Ref}
                                type={showRePassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle re-password visibility"
                                            onClick={handleClickShowRePassword}
                                            onMouseDown={handleMouseDownPassword}
                                            onMouseUp={handleMouseUpPassword}
                                            edge="end"
                                        >
                                            {showRePassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                placeholder="Re-enter Password"
                            />
                        </FormControl>

                    </>
                    //     <Input
                    //         inputRef={userPwd2Ref}
                    //         type="password"
                    //         placeholder="Re-enter Password"
                    //     ></Input>
                )}

                {!authStore.isLogin ? (
                    <Box row spaceX2>
                        <Button
                            variant="contained"
                            style={{ background: '#7B7B7B', borderRadius: '20px' }}
                            fullWidth
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            fullWidth
                            style={{ borderRadius: '20px' }}
                            onClick={() => {
                                const userNameElement = userNameRef.current as any
                                const pwdElement = userPwdRef.current as any
                                const pwd2Element = userPwd2Ref.current as any

                                if (mode === 'login') {
                                    onLogin(
                                        String(userNameElement.value),
                                        String(pwdElement.value),
                                    )
                                } else {
                                    onSignUp &&
                                        onSignUp(
                                            String(userNameElement.value),
                                            String(pwdElement.value),
                                            String(pwd2Element.value),
                                        )
                                }
                            }}
                        >
                            {mode === 'signup' ? 'Sign Up' : 'Login'}
                        </Button>
                    </Box>
                ) : (
                    <Box>
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={() => {
                                onContinue && onContinue()
                            }}
                        >
                            Continue
                        </Button>
                    </Box>
                )}
                <Box style={{ fontSize: '14px' }}>
                    <span>Have an account？</span>
                    <span style={{ color: '#D80000', cursor: 'pointer' }} onClick={() => {
                        setMode((prev) => {
                            return prev === 'login' ? 'signup' : 'login'
                        })
                    }}>   {mode === 'login' ? 'Sign Up' : 'Log in'}</span>
                </Box>
            </Box>
        </Box >
    )
}
export default LoginBox
