import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Box } from '@fower/react'
import { EditorProvider, useEditorStore } from './store/editor/useEditorStore'
import { observer } from 'mobx-react-lite'
import { useProductStore } from '../product_list/store/useProdustListStore'
import {
    ColorOption,
    LinkedShop,
    MyProductItemVO,
    ProductItemVO,
    ShopifyActionType, SUPPORTED_FONTS,
} from '../../TypeDeclare'
import { getURLParam } from '../../common/StringUtils'
import LoginModal from '../../common/LoginModal'
import { useAuthStore } from '../../store/useAuthStore'
import UploaderModal, { UploadAction } from './UploaderModal'
import { useNavigate } from 'react-router-dom'
import SetProductPropsModal from '../../common/SetProductPropsModal'
import ConfirmDialog from './components/center/ConfirmDialog'
import ProgressIndicator from '../../common/ProgressIndicator'
import { AlertColor, Button, MenuItem, Select, TextField } from '@mui/material'
import { useDashbardStore } from '../dashboard/store/useDashboardStore'
import TopBar from './components/top/NewTopBar'
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import AddIcon from "@mui/icons-material/Add";
import UploadDialog from "./components/left/UploadDialog";
import { fabric } from "fabric";
import ColorPicker from "./components/left/ColorPicker";
import ColorPickerColumn from "./components/common/ColorPickerColumn";
import ImageCarousel from "./components/center/ImageCarousel";
import "./NewEditor.css"
import Sortable from 'sortablejs';
import UploaderDialog from './components/center/UploadDialog'
import CirclePicker from '../editor/components/common/CirclePicker'
import SizePicker from './components/left/SizePicker'
import { EditorService } from '../editor/services/editorService';
import { doPost } from '../../common/commonService'
import { backgroundColorListType, exportImagesType, historyRefType, LayerData, layerListType, maskListType, myFabricObject } from './store/editor/NewEditor'
import { btnIconStyle, canvasStyleObj, capitalizeFirstLetter, downlandImages, get1600Base64Image, getLayer, getSize, isTextObject, pixelsToInches, resizeBase64Image, styles, title } from './store/editor/NewEditorUtil'

const AssembledEditor: FC = observer(() => {

    const productStore = useProductStore()
    const uploadImageService: EditorService = new EditorService();
    const editorStore = useEditorStore()
    const authStore = useAuthStore()
    const dashboardStore = useDashbardStore()
    const navigator = useNavigate()
    const productId = getURLParam('productId')
    const [newProduct, setNewProduct] = useState<ProductItemVO | undefined>(
        undefined,
    )
    const [showEditProduct, setShowEditProduct] = useState<boolean>(false)

    const [showProgress, setShowProgress] = useState<boolean>(false)
    const [updating, setUpdating] = useState<boolean>(false)
    const [message, setMessage] = useState<string>('')
    const [messageType, setMessageType] = useState<AlertColor>('info')

    const [confirmOpen, setConfirmOpen] = useState<boolean>(false)
    const [uploaderOpen, setUploaderOpen] = useState<boolean>(false)

    const [progress, setProgress] = useState<number>(0)

    const [selectedProductColor, setSelectedProductColor] = useState<ColorOption>({
        id: '',
        value: '',
        name: '',
    })

    const reloadProduct = useCallback(async () => {

        if (productId !== undefined) {
            const p = await productStore.loadSingleProdcut(productId)
            size_id.current = productStore.sizeOptions[0].id
            color_id.current = productStore.colorOptions[0].id
            setSelectedProductColor(productStore.colorOptions[0])
            initFoxPsd(p)
        }


    }, [productStore, productId])

    const reloadNewCreatedProduct = useCallback(async () => {
        if (editorStore.newCreatedProductId === '') return
        if (editorStore.newCreatedProductId !== undefined) {
            const p = await productStore.loadSingleProdcut(
                editorStore.newCreatedProductId,
            )
            setNewProduct(p)
        }
    }, [productStore, editorStore])

    const updateShopifyProducts = useCallback(
        async (
            productId: string,
            selectedShop: LinkedShop,
            action: ShopifyActionType,
        ) => {
            setShowProgress(true)
            setUpdating(true)
            const result = await dashboardStore.updateShopifyProduct(
                authStore.token,
                selectedShop.ctToken!,
                productId,
                action,
            )
            setUpdating(false)
            if (result) {
                if (action === 'add') {
                    setMessage('Product added to Shopify')
                    setMessageType('success')
                } else {
                    setMessage('Product removed from Shopify')
                    setMessageType('success')
                }
            } else {
                setMessage('Error')
                setMessageType('error')
            }
        },
        [authStore.token, dashboardStore],
    )

    useEffect(() => {
        reloadNewCreatedProduct().then(null)
    }, [editorStore.newCreatedProductId, reloadNewCreatedProduct, reloadProduct])
    useEffect(() => {
        reloadProduct().then(null)
    }, [productId, reloadProduct])

    useEffect(() => {
        authStore.loadUserProfile().then(result => {
            dashboardStore.setLinkedShops(result.rawLinkedShops)
        })
    }, [authStore, dashboardStore])

    //Menu
    const [font, setFont] = useState<string>('')
    const [fontColor, setFontColor] = useState<string>('#000000')
    const [quantity, setQuantity] = useState<number>(1)

    const [maskList, setMaskList] = useState<maskListType>([])

    const size_id = useRef<string | number>('')
    const color_id = useRef<string | number>('')

    const [open, setOpen] = useState<boolean>(false)

    const [active, setActive] = useState<number>(-1)

    const [backgroundColorList, setBackgroundColorList] = useState<backgroundColorListType>({})

    const canvasImg = useRef<string[]>([])

    const [canvasList, setCanvasList] = useState<LayerData[]>([])
    const [layerList, setLayerList] = useState<layerListType>({})
    const layersRef = useRef<layerListType>({});
    const [selectedLayer, setSelectedLayer] = useState<string | null>(null);
    const [isLayerPanelVisible, setIsLayerPanelVisible] = useState(true);
    const [isLayerLocked, setIsLayerLocked] = useState(false);

    const [designImg, setDesignImg] = useState<string[]>([])
    const [selectedImg, setSelectedImg] = useState<number>(0)

    const exportImages = useRef<exportImagesType>({})

    const [currentCanvasIndex, setCurrentCanvasIndex] = useState<number>(0); // 当前激活的画布 index

    const skus = useRef<string[]>([])

    function getCommonButtonSty(value: number) {
        return {
            width: '48%',
            height: '70px',
            background: active === value ? '#518DD9' : '#FFF',
            borderRadius: '15px',
            boxShadow: '0px 3px 6px 1px rgba(0,0,0,0.16)',
            fontSize: '16px',
            color: active === value ? '#FFF' : '#373737',
            display: 'flex',
            fontWeight: 'bold'
        }
    }

    // 初始化
    function initFoxPsd(data: any) {

        let resultData: any[] = []
        let layList: any = {}
        const maskList: { color: string, comment: string, id: number, image: string }[] = data['psd_clipping_masks']

        skus.current = typeof data.psd_sku === 'string' ? JSON.parse(data.psd_sku) : data.psd_sku

        setDesignImg(data['psd_layer_images'].map((item: { image: string }) => item.image))

        setMaskList(maskList.map(item => {
            return {
                comment: getLayer(item.comment.substr(18)) as string,
                image: item.image,
                productSize: getSize(item.comment.substr(18)).split(' ')[0].split('*'),
                actualSize: getSize(item.comment.substr(18)).split(' ')[1].split('*')
            }
        }))
        maskList.forEach((item, index: number) => {

            layList[index] = []
            setLayerList(layList)

            historyRef.current[index] = []
            redoStackRef.current[index] = []
            layerListHistoryRef.current[index] = []
            layerListRedoStackRef.current[index] = []

            exportImages.current[index] = []

            const layerName = getLayer(item.comment.substr(18)) as string

            resultData.push({
                id: '' + index,
                layerName: layerName,
                fabricCanvas: null,
            })

            setBackgroundColorList(pre => {
                return {
                    ...pre,
                    [index]: {
                        color: 'transparent',
                        visible: true
                    }
                }
            })
        });

        foxPsdLoad()
        setCanvasList(resultData);
    }

    const foxpsdObj = useRef<any>(null)

    const foxPsdLoad = () => {
        foxpsdObj.current = new window.foxpsd('xiaoguotu', {
            psdSkus: skus.current,
            goodsSku: '1713508404989522656',
            token: 'Basic d4a372e3dce39582a93fce2fa545d25b6b2478c50ebb9c36bef8f56f54aa0a75',
            onload: () => {
                foxpsdObj.current.command({
                    layers: 'colour',
                    color: productStore.colorOptions[0].value
                })
            }
        })

    }

    // 保存撤销和恢复的栈
    const historyRef = useRef<historyRefType>({});
    const redoStackRef = useRef<historyRefType>({});

    const layerListHistoryRef = useRef<{ [key: string]: any }>({}); // 保存 layerList 历史记录
    const layerListRedoStackRef = useRef<{ [key: string]: any }>({}); // 保存 layerList 重做栈

    const isUndoFlag = useRef<boolean>(false)

    const maxHistory = 50; // 最大历史记录数，防止内存泄漏
    function saveState(obj: fabric.Object, type: 'add' | 'edit' | 'minus') {
        const state = {
            object: obj,
            type,
            position: {
                left: obj.left as number,
                top: obj.top as number,
                angle: obj.angle as number,  // 你也可以保存旋转角度、缩放等其他属性
                scaleX: obj.scaleX as number,
                scaleY: obj.scaleY as number
            }
        }
        if (historyRef.current[currentCanvasIndex].length >= maxHistory) {
            historyRef.current[currentCanvasIndex].shift(); // 超过最大历史记录，移除最旧的记录
        }
        historyRef.current[currentCanvasIndex].push(state);
        // 每次新的操作，重做栈应被清空
        // redoStackRef.current = [];
    }

    // TODO
    const undo = () => {

        if (historyRef.current[currentCanvasIndex].length > 0) {

            isUndoFlag.current = true

            const lastObj = historyRef.current[currentCanvasIndex].pop()
            if (lastObj!.type === 'add') {
                const lastLayer = layerListHistoryRef.current[currentCanvasIndex].pop()
                layerListRedoStackRef.current[currentCanvasIndex].push(lastLayer)
                setLayerList(pre => {
                    const updatedLayers = { ...pre };
                    const reverseLayerList: fabric.Object[] = []

                    layerListHistoryRef.current[currentCanvasIndex].forEach((item: fabric.Object) => {
                        reverseLayerList.unshift(item)
                    })

                    updatedLayers[currentCanvasIndex] = reverseLayerList

                    return updatedLayers
                })
            }
            redoStackRef.current[currentCanvasIndex].push(lastObj!)

            canvasList[currentCanvasIndex].fabricCanvas.clear()

            historyRef.current[currentCanvasIndex].forEach((item, index) => {
                item.object.moveTo(index)

                item.object.set({
                    left: item.position.left,
                    top: item.position.top,
                    angle: item.position.angle,
                    scaleX: item.position.scaleX,
                    scaleY: item.position.scaleY
                })
                canvasList[currentCanvasIndex].fabricCanvas.add(item.object)
            })

            canvasList[currentCanvasIndex].fabricCanvas.renderAll();
            isUndoFlag.current = false
        }

    };

    const redo = () => {
        if (redoStackRef.current[currentCanvasIndex].length > 0) {
            isUndoFlag.current = true
            const lastRedoState = redoStackRef.current[currentCanvasIndex].pop();

            if (lastRedoState?.type === 'add') {
                const layerListRedoStack = layerListRedoStackRef.current[currentCanvasIndex].pop();
                layerListHistoryRef.current[currentCanvasIndex].push(layerListRedoStack)
                setLayerList(pre => {
                    const updatedLayers = { ...pre };
                    const reverseLayerList: fabric.Object[] = []

                    layerListHistoryRef.current[currentCanvasIndex].forEach((item: fabric.Object) => {
                        reverseLayerList.unshift(item)
                    })

                    updatedLayers[currentCanvasIndex] = reverseLayerList

                    return updatedLayers
                })
            }

            canvasList[currentCanvasIndex].fabricCanvas.add(lastRedoState!.object)
            lastRedoState!.object.set({
                left: lastRedoState!.position.left,
                top: lastRedoState!.position.top,
                angle: lastRedoState!.position.angle,
                scaleX: lastRedoState!.position.scaleX,
                scaleY: lastRedoState!.position.scaleY
            })
            saveState(lastRedoState!.object, lastRedoState!.type)
            canvasList[currentCanvasIndex].fabricCanvas.getObjects().forEach((item, index) => {
                item.moveTo(index)
            })
            canvasList[currentCanvasIndex].fabricCanvas.setActiveObject(lastRedoState!.object)
            canvasList[currentCanvasIndex].fabricCanvas.renderAll();
            isUndoFlag.current = false
        }
    };

    function getItemUrlData(objectImgs: fabric.Object[]) {
        let images: string[] = []
        objectImgs.forEach(obj => {
            // Create a clone of the object to avoid modifying the original
            const clone = fabric.util.object.clone(obj);

            // Create a temporary canvas to render the object
            const tempCanvas = document.createElement('canvas');
            const tempContext = tempCanvas.getContext('2d');

            // Set the size of the temporary canvas to the object's size
            tempCanvas.width = clone.getScaledWidth();
            tempCanvas.height = clone.getScaledHeight();

            // Render the cloned object onto the temporary canvas
            clone.set({ left: 0, top: 0 }); // Position object at the top-left corner
            clone.render(tempContext);

            // Get the image data URL from the temporary canvas
            const imageDataURL = tempCanvas.toDataURL();
            images.push(imageDataURL);
        });

        return images
    }

    const currentIndex = useRef<number>(0)

    // 辅助线对象
    let horizontalLine: any = useRef(null);
    let verticalLine: any = useRef(null);
    const margin = 10; // 辅助线显示的距离容差
    function updateGuides() {
        const canvas = canvasList[currentCanvasIndex].fabricCanvas
        if (!canvas.getActiveObject()) return;

        const obj = canvas.getActiveObject();
        const center = obj.getCenterPoint();

        const canvasWidth = canvas.width;
        const canvasHeight = canvas.height;

        // 获取中心点附近的坐标范围
        const isHorizontalLineVisible = Math.abs(center.y - (canvasHeight as number) / 2) < margin;
        const isVerticalLineVisible = Math.abs(center.x - (canvasWidth as number) / 2) < margin;

        // 更新水平辅助线
        if (isHorizontalLineVisible) {
            if (!horizontalLine.current) {
                horizontalLine.current = new fabric.Line([0, center.y, canvasWidth as number, center.y], {
                    stroke: 'red',
                    strokeWidth: 1,
                    selectable: false,
                    evented: false,
                    opacity: 0.5
                });
                canvas.add(horizontalLine.current);
            } else {
                horizontalLine.current.set({ x1: 0, y1: center.y, x2: canvasWidth, y2: center.y });
            }
        } else if (horizontalLine.current) {
            canvas.remove(horizontalLine.current);
            horizontalLine.current = null;
        }

        // 更新垂直辅助线
        if (isVerticalLineVisible) {
            if (!verticalLine.current) {
                verticalLine.current = new fabric.Line([center.x, 0, center.x, canvasHeight as number], {
                    stroke: 'red',
                    strokeWidth: 1,
                    selectable: false,
                    evented: false,
                    opacity: 0.5
                });
                canvas.add(verticalLine.current);
            } else {
                verticalLine.current.set({ x1: center.x, y1: 0, x2: center.x, y2: canvasHeight });
            }
        } else if (verticalLine.current) {
            canvas.remove(verticalLine.current);
            verticalLine.current = null;
        }

        canvas.renderAll();
    }


    const bindEvents = (instance: fabric.Canvas) => {

        instance.on('object:added', (e) => {

            setIsLayerPanelVisible(true)
            setBtnActive(0)

            if (!isUndoFlag.current && e.target!.type !== 'line') {
                saveState(e.target as fabric.Object, 'minus')
            }


            const addedObject = e.target;
            if (addedObject) {
                exportImages.current[currentIndex.current] = getItemUrlData(canvasList[currentCanvasIndex].fabricCanvas.getObjects())
                // delete btn
                addedObject.controls.deleteControl = new fabric.Control({
                    x: 0.5,
                    y: -0.5,
                    offsetY: -16,
                    offsetX: 16,
                    cursorStyle: 'pointer',
                    mouseUpHandler: (eventData, transformData, x, y) => {
                        const target = transformData.target;
                        const canvas = target?.canvas;

                        if (canvas && target) {
                            canvas.remove(target);
                            canvas.renderAll();
                            commandHandle()

                            const idToDelete = layersRef.current[currentIndex.current].find((key) => {
                                return key.id == (target as myFabricObject).id
                            });

                            if (idToDelete) {

                                setLayerList(prevLayers => {
                                    const updatedLayers = { ...prevLayers };
                                    if (!updatedLayers[currentIndex.current]) {
                                        updatedLayers[currentIndex.current] = [];
                                    }

                                    // 过滤掉要删除的对象
                                    updatedLayers[currentIndex.current] = updatedLayers[currentIndex.current].filter(item => item != idToDelete);

                                    return { ...updatedLayers };
                                });
                                if (selectedLayer === idToDelete.id) {
                                    setSelectedLayer(null);
                                    setIsLayerLocked(false);
                                }
                            }



                        }
                        return false;
                    },
                    render: (ctx, left, top, styleOverride, fabricObject) => {
                        const circleSize = 20;
                        ctx.save();
                        ctx.translate(left, top);
                        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle!));

                        // Draw circle
                        ctx.beginPath();
                        ctx.arc(0, 0, circleSize / 2, 0, 2 * Math.PI);
                        ctx.fillStyle = '#ff0000';
                        ctx.fill();

                        // Draw "X"
                        ctx.strokeStyle = '#ffffff';
                        ctx.lineWidth = 2;
                        ctx.beginPath();
                        ctx.moveTo(-circleSize / 4, -circleSize / 4);
                        ctx.lineTo(circleSize / 4, circleSize / 4);
                        ctx.moveTo(circleSize / 4, -circleSize / 4);
                        ctx.lineTo(-circleSize / 4, circleSize / 4);
                        ctx.stroke();

                        ctx.restore();
                    },
                });

            }
        });

        // 假设 canvas 是你的 fabric.Canvas 实例
        instance.on('mouse:wheel', function (opt) {
            const delta = opt.e.deltaY; // 获取滚轮的方向，负值表示向上滚动，正值表示向下滚动
            const zoomFactor = 0.1; // 缩放因子，可以根据需要调整

            const activeObject = instance.getActiveObject(); // 获取当前选中的对象
            if (activeObject && activeObject.type === 'image') {
                // 获取当前的缩放比例
                let scaleX = activeObject.scaleX;
                let scaleY = activeObject.scaleY;

                // 根据滚轮方向调整缩放比例
                if (delta < 0) {
                    // 向上滚动，放大
                    scaleX! += zoomFactor;
                    scaleY! += zoomFactor;
                } else {
                    // 向下滚动，缩小
                    scaleX! -= zoomFactor;
                    scaleY! -= zoomFactor;

                    // 防止缩放过小
                    if (scaleX! < 0.1) scaleX = 0.1;
                    if (scaleY! < 0.1) scaleY = 0.1;
                }

                // 设置新的缩放比例
                activeObject.scaleX = scaleX;
                activeObject.scaleY = scaleY;

                // 应用缩放和重新渲染
                activeObject.setCoords(); // 更新对象的坐标
                instance.renderAll();
                exportImages.current[currentCanvasIndex] = getItemUrlData(canvasList[currentCanvasIndex].fabricCanvas.getObjects())

            }
            commandHandle()
            // 禁止页面滚动
            opt.e.preventDefault();
            opt.e.stopPropagation();
        });



        instance.on('object:modified', (e) => {

            // 鼠标松下清除辅助线
            const canvasInstance = canvasList[currentIndex.current].fabricCanvas

            const backgroundColor = canvasInstance.backgroundColor
            const clazzs = canvasInstance.getObjects().filter(item => item.type !== 'line')
            canvasInstance.clear()
            canvasInstance.setBackgroundColor(backgroundColor as string, () => { })
            clazzs.forEach(item => {
                canvasInstance.add(item)
            })
            canvasInstance.renderAll()
            horizontalLine.current = null;
            verticalLine.current = null;

            exportImages.current[currentCanvasIndex] = getItemUrlData(canvasList[currentCanvasIndex].fabricCanvas.getObjects())

            setLayerList(pre => {
                // 创建当前画布的 layerList 副本
                const updatedLayerList = [...pre[currentCanvasIndex]];

                // 找到被修改对象的索引
                const modifiedIndex = updatedLayerList.findIndex(item => item.id === (e.target as myFabricObject).id);

                // 用修改后的对象替换原来的对象
                if (modifiedIndex !== -1) {
                    updatedLayerList.splice(modifiedIndex, 1, e.target!);
                }

                // 返回一个新的 layerList 对象，确保只更新当前画布的状态
                return {
                    ...pre,
                    [currentCanvasIndex]: updatedLayerList
                };
            });

            commandHandle()
        });

        instance.on('object:remove', (e) => {
            exportImages.current[currentCanvasIndex] = getItemUrlData(canvasList[currentCanvasIndex].fabricCanvas.getObjects())
            if (!isUndoFlag.current && e.target!.type !== 'line') {
                saveState(e.target as fabric.Object, 'add')
            }
            commandHandle()
        });


        // 监听对象移动事件
        instance.on('mouse:move', function (e) {
            updateGuides()
        });
        instance.on('mouse:up', function (e) {

            const selectedObject = e.target as fabric.Object & { id: string };

            // 检查是否有选中对象
            if (selectedObject) {
                handleLayerClick(selectedObject.id)
                // 判断是否是文本对象
                if (selectedObject instanceof fabric.Textbox || selectedObject instanceof fabric.Text) {
                    // 获取字体信息
                    const fontColor = selectedObject.fill; // 字体颜色
                    const fontFamily = selectedObject.fontFamily; // 字体

                    setFont(fontFamily as string)
                    setFontColor(fontColor as string)
                    setActive(1)
                }
            }

            if (!isUndoFlag.current && e.target && e.target.type !== 'line') {
                saveState(e.target as fabric.Object, 'edit')
            }

        });
    }

    /**
     * canvas resize
     */
    function resizeCanvas() {
        canvasList.forEach((canvasData, index) => {
            const dom = document.querySelector('.overlayImage' + index) as HTMLImageElement;
            const canvasBoxContainer = document.getElementById('canvasBox' + index) as HTMLDivElement;

            if (dom && canvasBoxContainer) {
                const originalWidth = Number(maskList[index].actualSize[1]) / 2; // 原始宽度
                const originalHeight = Number(maskList[index].actualSize[0]) / 2; // 原始高度

                const body = document.querySelector('.bookListBody') as HTMLDivElement;

                // 获取当前窗口的宽度和高度
                const windowWidth = body.offsetWidth;
                const windowHeight = body.offsetHeight;

                // 计算缩放比例，保证宽高等比缩放
                const scale = Math.min(windowWidth / originalWidth, windowHeight / originalHeight);

                // 计算新的宽度和高度
                const newWidth = originalWidth * scale;
                const newHeight = originalHeight * scale;

                // 设置画布容器和图片的新宽度和高度
                canvasBoxContainer.style.width = newWidth + 'px';
                canvasBoxContainer.style.height = newHeight + 'px';
                dom.style.width = newWidth + 'px';
                dom.style.height = newHeight + 'px';

                // 如果fabricCanvas存在，调整fabricCanvas的宽度和高度
                if (canvasData.fabricCanvas) {
                    canvasData.fabricCanvas.setWidth(newWidth);
                    canvasData.fabricCanvas.setHeight(newHeight);
                    canvasData.fabricCanvas.renderAll(); // 重新渲染画布
                }
            }
        });
    }

    useEffect(() => {

        const container = document.getElementById('canvasBox0') as HTMLDialogElement;
        if (container) {

            canvasList.forEach((canvasData, index) => {
                const dom = document.querySelector('.overlayImage' + index) as HTMLImageElement
                const canvasBoxContainer = document.getElementById('canvasBox' + index) as HTMLDialogElement;
                const width = Number(maskList[index].actualSize[1]) / 2
                const height = Number(maskList[index].actualSize[0]) / 2

                if (!canvasData.fabricCanvas) {
                    const canvas = new fabric.Canvas(`fabricCanvas${index}`, {
                        width,
                        height,
                        preserveObjectStacking: true
                    })

                    canvasData.fabricCanvas = canvas
                }
                canvasBoxContainer.style.height = height + 'px'
                dom.style.width = width + 'px'
                dom.style.height = height + 'px'

            });

            bindEvents(canvasList[currentCanvasIndex].fabricCanvas)

            // 假设canvasInstance是你的Fabric.js画布实例
            const canvasInstance = canvasList[currentCanvasIndex].fabricCanvas

            // 键盘事件监听器
            document.addEventListener('keydown', function (event) {
                // 获取当前选中的对象
                const activeObject = canvasInstance.getActiveObject();
                if (activeObject) {
                    switch (event.key) {
                        case 'ArrowLeft':
                            // 向左移动
                            activeObject.left! -= 1;
                            break;
                        case 'ArrowRight':
                            // 向右移动
                            activeObject.left! += 1;
                            break;
                        case 'ArrowUp':
                            // 向上移动
                            activeObject.top! -= 1;
                            break;
                        case 'ArrowDown':
                            // 向下移动
                            activeObject.top! += 1;
                            break;
                        default:
                            // 非箭头键按下，不做处理
                            return;
                    }
                    // 防止画布上的其他行为被触发（如画布拖动）
                    event.preventDefault();

                    canvasInstance.renderAll();
                }
            });
        }

        const canvasElement = document.getElementById(`canvasBox${currentCanvasIndex}`) as HTMLDivElement;
        if (canvasElement) {
            // 添加点击事件监听器到整个文档
            // document.addEventListener('click', function (event: MouseEvent) {
            //     // 检查点击是否在画布内部
            //     const isClickInsideCanvas = canvasElement.contains(event.target as Node);
            //     // 检查点击是否在包含 class 为 'sortItem' 的元素内部
            //     const isClickInsideSortItem = (event.target as HTMLElement).closest('.LayerPaneDialog') !== null;
            //     const isClickPositionSortItem = (event.target as HTMLElement).closest('.positionBox') !== null;
            //     console.log(isClickInsideCanvas, isClickInsideSortItem, 'llll')
            //     // 如果点击不在画布内部，取消激活对象
            //     if (!isClickInsideCanvas && !isClickInsideSortItem && !isClickPositionSortItem) {
            //         canvasList[currentCanvasIndex].fabricCanvas.discardActiveObject();
            //         setSelectedLayer(null)
            //         canvasList[currentCanvasIndex].fabricCanvas.renderAll();  // 重新渲染画布
            //     }
            // });

            // canvas resize
            window.addEventListener('resize', resizeCanvas);
            window.dispatchEvent(new Event('resize'));
        }
        return () => {
            canvasList.forEach((canvasData) => {
                if (canvasData.fabricCanvas) {
                    // 取消绑定事件或执行必要的清理
                    canvasData.fabricCanvas.off('object:added');
                    canvasData.fabricCanvas.off('object:modified');
                    // ... 其他必要的解绑
                }
            });

            // canvas resize
            window.removeEventListener('resize', resizeCanvas);
        };
    }, [canvasList, currentCanvasIndex]);


    useEffect(() => {

        if (isLayerPanelVisible) {

            const el = document.querySelector('#sortBox') as HTMLElement;

            const sortable = Sortable.create(el, {
                handle: ".sortItem",
                onMove: function (evt) {
                    const targetElement = evt.related;   // 拖动目标位置的元素

                    if (targetElement.classList.contains('sortItem1')) {
                        return false;  // 返回 false 阻止移动
                    }

                },
                onEnd: (evt) => {

                    // 获取排序后的 id 列表
                    const newOrderIds = Array.from(el.querySelectorAll('.sortItem'))
                        .map(item => item.getAttribute('data-id') as string);

                    // 获取当前 Canvas 对象
                    const objects = canvasList[currentCanvasIndex].fabricCanvas.getObjects();
                    let layerListIndex = newOrderIds.length - 1;                    // 创建 ID 到 Canvas 对象的映射

                    // @ts-ignore
                    const objectMap = new Map(objects.map(obj => [obj.id, obj]));
                    // 按新的排序顺序更新 Canvas 对象的层级
                    newOrderIds.forEach((id, index) => {
                        const object = objectMap.get(id);

                        if (object) {
                            object.moveTo(layerListIndex);
                            --layerListIndex
                        }
                    });
                    canvasList[currentCanvasIndex].fabricCanvas.renderAll();

                    const { newIndex, oldIndex } = evt

                    // 获取当前 Canvas 对象的层列表
                    const currentLayerList = layersRef.current![currentCanvasIndex];

                    // 确保当前层列表存在并且是数组
                    if (!Array.isArray(currentLayerList)) return;

                    // 获取需要移动的对象 ID 和对象
                    const itemId = evt.item.dataset.id as string;

                    const objToMove = currentLayerList.find(item => item.id == itemId);

                    if (!objToMove) return;

                    if (newIndex === oldIndex) return;

                    const newLayerList = currentLayerList.filter(item => item.id != itemId)

                    newLayerList.splice(newIndex!, 0, objToMove);  // 插入新位置

                    // 更新 layerList
                    setLayerList(prevLayers => ({
                        ...prevLayers,
                        [currentCanvasIndex]: [...newLayerList],
                    }));

                    commandHandle()
                }
            });

            // 清理函数以防止内存泄漏
            return () => {
                sortable.destroy();
            };
        }
    }, [currentCanvasIndex, isLayerPanelVisible, canvasList]);

    useEffect(() => {

        if (layerList[currentCanvasIndex] && layerList[currentCanvasIndex].length) {
            let layerListIndex = layerList[currentCanvasIndex].length - 1;
            layerList[currentCanvasIndex].forEach((item, index) => {

                if (item) {
                    item.moveTo(layerListIndex);
                    --layerListIndex
                }
            });
        }

        layersRef.current = layerList
        if (canvasList.length > 0 && (canvasList.length === skus.current.length)) {
            commandHandle()
        }

    }, [layerList])

    const [isLargeScreen, setIsLargeScreen] = useState(window.matchMedia('(min-width: 1200px)').matches);

    function resizeLayerItem() {
        const widthInInches = pixelsToInches(window.screen.width);
        const heightInInches = pixelsToInches(window.screen.height);
        const diagonalInInches = Math.sqrt(Math.pow(widthInInches, 2) + Math.pow(heightInInches, 2));
        setIsLargeScreen(diagonalInInches > 16);
    }
    useEffect(() => {
        window.addEventListener("resize", resizeLayerItem)

        document.addEventListener('foxpsd:carouselchange', (e: Event) => {
            const customEvent = e as CustomEvent<{ index: number }>;
            setSelectedImg(customEvent.detail.index)
        })

        return () => {
            window.removeEventListener("resize", resizeLayerItem)
            document.removeEventListener('foxpsd:carouselchange', (e) => { })
        }
    }, []);

    // 上传图片
    const handleFileChange = (file: Blob) => {

        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const canvasIntance = canvasList[currentCanvasIndex].fabricCanvas
                if (!canvasIntance) return
                const dataURL = e.target?.result as string;
                fabric.Image.fromURL(dataURL, (img) => {
                    const id = `img - ${Date.now()}`;
                    img.set({
                        left: 0,
                        top: 0,
                        angle: 0,
                        selectable: true,
                        id,
                        borderColor: 'blue',         // 设置边框颜色
                        borderSoliedArray: [5, 5],     // 设置虚线样式 [线段长度, 间距]
                        cornerColor: 'blue',        // 控制角的颜色
                        cornerSize: 10,              // 控制角的尺寸
                        cornerStyle: 'circle',       // 控制角的形状 (circle 或 rect)
                        transparentCorners: false    // 控制角不透明
                    } as myFabricObject);
                    // 按照指定的宽度或高度缩放图片
                    img.scaleToWidth(100); // 将宽度缩放到300px，高度将按比例调整
                    img.scaleToHeight(100); // 将高度缩放到200px，宽度将按比例调整

                    canvasIntance.add(img);
                    canvasIntance.setActiveObject(img);
                    canvasIntance.renderAll();

                    commandHandle()

                    setLayerList(prevLayers => {
                        const updatedLayers = { ...prevLayers };
                        if (!updatedLayers[currentCanvasIndex]) {
                            updatedLayers[currentCanvasIndex] = [];
                        }
                        // 确保没有重复的 ID
                        const currentLayerIds = updatedLayers[currentCanvasIndex].map(item => item.id);
                        if (!currentLayerIds.includes((img as myFabricObject).id)) {
                            updatedLayers[currentCanvasIndex] = [img as fabric.Object, ...updatedLayers[currentCanvasIndex]];
                        }
                        return updatedLayers;
                    });

                    layerListHistoryRef.current[currentCanvasIndex].push(img)
                    // 设置所选层
                    setSelectedLayer(id);

                });
            };
            reader.readAsDataURL(file);
            setOpen(false)
            setActive(-1)
        }
    };

    const alignSelectedObjects = (
        alignment: 'left' | 'center' | 'right' | 'top' | 'bottom',
        orientation: 'horizontal' | 'vertical'
    ) => {
        const canvas = canvasList[currentCanvasIndex].fabricCanvas;
        if (!canvas) return;

        const activeObject = canvas.getActiveObject();

        if (activeObject && (activeObject instanceof fabric.Textbox || activeObject instanceof fabric.Image)) {
            const canvasWidth = canvas.getWidth();
            const canvasHeight = canvas.getHeight();

            // Adjust for scaling factors
            const scaleX = activeObject.scaleX || 1;
            const scaleY = activeObject.scaleY || 1;

            if (orientation === 'horizontal') {
                if (alignment === 'left') {
                    (activeObject as fabric.Object).set('left', 0);
                } else if (alignment === 'center') {
                    const centerX = canvasWidth / 2;
                    (activeObject as fabric.Object).set('left', centerX - (activeObject.width! * scaleX / 2));
                } else if (alignment === 'right') {
                    (activeObject as fabric.Object).set('left', canvasWidth - (activeObject.width! * scaleX));
                }
            } else if (orientation === 'vertical') {
                if (alignment === 'top') {
                    (activeObject as fabric.Object).set('top', 0);
                } else if (alignment === 'center') {
                    const centerY = canvasHeight / 2;
                    (activeObject as fabric.Object).set('top', centerY - (activeObject.height! * scaleY / 2));
                } else if (alignment === 'bottom') {
                    (activeObject as fabric.Object).set('top', canvasHeight - (activeObject.height! * scaleY));
                }
            }

            canvas.renderAll();
            commandHandle()
        } else if (activeObject instanceof fabric.ActiveSelection) {
            const objects = activeObject.getObjects();

            if (orientation === 'horizontal') {
                if (alignment === 'left') {
                    const minLeft = Math.min(...objects.map(obj => obj.left!));
                    objects.forEach(obj => obj.set('left', minLeft));
                } else if (alignment === 'center') {
                    const minLeft = Math.min(...objects.map(obj => obj.left!));
                    const maxRight = Math.max(...objects.map(obj => obj.left! + obj.width! * obj.scaleX!));
                    const centerX = (minLeft + maxRight) / 2;
                    objects.forEach(obj => obj.set('left', centerX - (obj.width! * obj.scaleX! / 2)));
                } else if (alignment === 'right') {
                    const maxRight = Math.max(...objects.map(obj => obj.left! + obj.width! * obj.scaleX!));
                    objects.forEach(obj => obj.set('left', maxRight - obj.width! * obj.scaleX!));
                }
            } else if (orientation === 'vertical') {
                if (alignment === 'top') {
                    const minTop = Math.min(...objects.map(obj => obj.top!));
                    objects.forEach(obj => obj.set('top', minTop));
                } else if (alignment === 'center') {
                    const minTop = Math.min(...objects.map(obj => obj.top!));
                    const maxBottom = Math.max(...objects.map(obj => obj.top! + obj.height! * obj.scaleY!));
                    const centerY = (minTop + maxBottom) / 2;
                    objects.forEach(obj => obj.set('top', centerY - (obj.height! * obj.scaleY! / 2)));
                } else if (alignment === 'bottom') {
                    const maxBottom = Math.max(...objects.map(obj => obj.top! + obj.height! * obj.scaleY!));
                    objects.forEach(obj => obj.set('top', maxBottom - obj.height! * obj.scaleY!));
                }
            }
            canvas.requestRenderAll();
        }
    };

    // yichu元素
    const removeItem = () => {

        const canvas = canvasList[currentIndex.current].fabricCanvas
        if (!canvas) return
        const activeObject = canvas.getActiveObject();

        if (activeObject) {

            const idToDelete = layersRef.current[currentIndex.current].find((key) => {
                return key === activeObject
            });

            canvas.remove(activeObject);
            canvas.renderAll();
            commandHandle()
            if (idToDelete) {
                setLayerList(prevLayers => {
                    const updatedLayers = { ...prevLayers };
                    if (!updatedLayers[currentIndex.current]) {
                        updatedLayers[currentIndex.current] = [];
                    }

                    // 过滤掉要删除的对象
                    updatedLayers[currentIndex.current] = updatedLayers[currentIndex.current].filter(item => item != idToDelete);

                    return { ...updatedLayers };
                });

                if (selectedLayer === idToDelete.id) {
                    setSelectedLayer(null);
                    setIsLayerLocked(false);
                }
            }
        }
    };

    // foxpsd效果图渲染图层
    const commandHandle = async () => {
        const url = canvasList[currentCanvasIndex].fabricCanvas.toDataURL({
            format: 'png',
            quality: 1.0
        })

        if (url.length > 6) {
            const base641600url = await get1600Base64Image(url)
            canvasImg.current[currentCanvasIndex] = base641600url as string

            const size = maskList[currentCanvasIndex].actualSize

            resizeBase64Image(url, Number(size[1]), Number(size[0])).then(resImg => {
                // console.log(resImg)
                if ((resImg as string).length > 6) {
                    foxpsdObj.current.command({
                        image: resImg,
                        layers: canvasList[currentCanvasIndex].layerName
                    })
                }

            })

        }
    }

    const addText = () => {
        const canvasIntance = canvasList[currentCanvasIndex].fabricCanvas
        if (!canvasIntance) return;

        const id = `text - ${Date.now()}`;
        const text = new fabric.Textbox('Hello, world!', {
            left: 0,
            top: 0,
            width: 140,
            fontFamily: font,
            fill: fontColor,
            fontSize: 20,
            selectable: true,
            evented: true,
            editable: true,
            id,
            borderColor: 'blue',         // 设置边框颜色
            borderSoliedArray: [5, 5],     // 设置虚线样式 [线段长度, 间距]
            cornerColor: 'blue',        // 控制角的颜色
            cornerSize: 10,              // 控制角的尺寸
            cornerStyle: 'circle',       // 控制角的形状 (circle 或 rect)
            transparentCorners: false    // 控制角不透明
        } as myFabricObject);


        canvasIntance.add(text);
        canvasIntance.setActiveObject(text);
        canvasIntance.renderAll();

        commandHandle()

        // 更新层数据
        setLayerList(prevLayers => {
            const updatedLayers = { ...prevLayers };
            if (!updatedLayers[currentCanvasIndex]) {
                updatedLayers[currentCanvasIndex] = [];
            }
            // 确保没有重复的 ID

            const currentLayerIds = updatedLayers[currentCanvasIndex].map(item => item.id);
            if (!currentLayerIds.includes((text as myFabricObject).id)) {
                updatedLayers[currentCanvasIndex] = [text as fabric.Object, ...updatedLayers[currentCanvasIndex]];
            }

            return updatedLayers;
        });
        layerListHistoryRef.current[currentCanvasIndex].push(text)

        // 设置所选层
        setSelectedLayer(id);

    };


    const handleLayerClick = (layerId: string) => {
        const canvasInstance = canvasList[currentCanvasIndex].fabricCanvas
        if (!canvasInstance) return
        if (layerId === 'background') {
            setSelectedLayer(null);
            // 取消激活当前选中的对象
            canvasInstance.discardActiveObject();
            canvasInstance.renderAll();
            return
        }
        setSelectedLayer(layerId);

        const object = layerList[currentCanvasIndex].find(layer => layer.id == layerId);

        if (object) {
            canvasInstance?.setActiveObject(object);
            canvasInstance?.renderAll();
        }
    };

    const toggleLayerVisibility = (layerId: string) => {

        const object = layerList[currentCanvasIndex].find(item => item.id == layerId);

        const backgroundVisibleCP = !backgroundColorList[currentCanvasIndex].visible
        const canvasIntance = canvasList[currentCanvasIndex].fabricCanvas
        if (layerId === 'background') {

            if (!backgroundVisibleCP) {
                canvasIntance.setBackgroundColor('transparent', canvasIntance.renderAll.bind(canvasIntance));
            } else {
                canvasIntance.setBackgroundColor(backgroundColorList[currentCanvasIndex].color, canvasIntance.renderAll.bind(canvasIntance));
            }
            setBackgroundColorList(pre => {
                return {
                    ...pre,
                    [currentCanvasIndex]: {
                        visible: backgroundVisibleCP,
                        color: pre[currentCanvasIndex].color
                    }
                }
            })

            commandHandle()
        }
        if (object) {
            object.visible = !object.visible;

            canvasIntance.renderAll();

            commandHandle()

            setLayerList(prevLayers => {
                const updatedLayers = { ...prevLayers };
                if (!updatedLayers[currentCanvasIndex]) {
                    updatedLayers[currentCanvasIndex] = [];
                }
                // 确保没有重复的 ID
                const currentLayerIds = updatedLayers[currentCanvasIndex].map(item => item.id);

                if (!currentLayerIds.includes(object.id)) {
                    updatedLayers[currentCanvasIndex] = [...updatedLayers[currentCanvasIndex], object];
                }
                return updatedLayers;
            });
        }
    };


    const toggleLockLayer = (layerId: string) => {

        const object = layerList[currentCanvasIndex].find(item => item.id == layerId);
        if (object) {
            object.lockMovementX = !object.lockMovementX;
            object.lockMovementY = !object.lockMovementY;

            setIsLayerLocked(object.lockMovementX && object.lockMovementY);
            canvasList[currentCanvasIndex].fabricCanvas?.renderAll();
        }
    };

    const handleOpacityChange = (layerId: string, opacity: number) => {

        const object = layerList[currentCanvasIndex].find(item => item.id == layerId);
        if (object) {
            object.set({ opacity });
            canvasList[currentCanvasIndex].fabricCanvas?.renderAll();

            setLayerList(prevLayers => {
                const updatedLayers = { ...prevLayers };
                if (!updatedLayers[currentCanvasIndex]) {
                    updatedLayers[currentCanvasIndex] = [];
                }
                // 确保没有重复的 ID
                const currentLayerIds = updatedLayers[currentCanvasIndex].map(item => item.id);

                if (!currentLayerIds.includes(object.id)) {
                    updatedLayers[currentCanvasIndex] = [...updatedLayers[currentCanvasIndex], object];
                }
                return updatedLayers;
            });

            commandHandle()

        }
    };

    useEffect(() => {
        if (productStore.sizeOptions.length > 0) {
            const defaultSize = productStore.sizeOptions[0];
            productStore.setSize(defaultSize);
        }
    }, [productStore.sizeOptions]);

    const [btnActive, setBtnActive] = useState<number>(0);

    const onUploadAction = (action: UploadAction) => {
        if (action === 'retry') {
            editorStore.resetUpload()
        } else if (action === 'cancel') {
            global.location.reload()
        } else if (action === 'buy') {
            navigator(editorStore.purchaseURLAfterUpload)
        } else if (action === 'dashboard') {
            navigator('/dashboard')
        } else if (action === 'sell') {
            reloadProduct().then(null)
            setShowEditProduct(true)
        }
    }

    return (
        <Box h="100vh" flexDirection="column" css={{ background: '#F8F8F8' }}>
            <TopBar
                onRedo={() => {

                    redo()
                }}
                disabled={false}
                onOk={async () => {
                    if (!authStore.token) {
                        authStore.setShowLoginModalOnEditor(true)
                        return
                    }
                    let exportItemImages: string[] = []
                    Object.values(exportImages.current).forEach(exportImage => {
                        exportItemImages = [...exportItemImages, ...exportImage]
                    })

                    let newExportImages: { data: string, format: string }[] = exportItemImages.map(item => {
                        return {
                            data: item.split(',')[1],
                            format: item.split(',')[0].split('/')[1].split(';')[0]
                        }
                    })

                    let canvasImgList: { data: string, format: string }[] = canvasImg.current.map(item => {
                        return {
                            data: item.split(',')[1],
                            format: item.split(',')[0].split('/')[1].split(';')[0]
                        }
                    })
                    const res = await foxpsdObj.current.canvasToImg()
                    let imgList: { data: string, format: string }[] = res.map((item: string) => {
                        return {
                            data: item.split(',')[1],
                            format: item.split(',')[0].split('/')[1].split(';')[0]
                        }
                    })

                    const allImg = [...imgList, ...canvasImgList, ...newExportImages].filter(img => Boolean(img))

                    setUploaderOpen(true)
                    // @ts-ignore
                    const uploadRes = await uploadImageService.uploadImageItems(allImg, (p) => {
                        setProgress(p)
                    })
                    const previewImages = allImg.map((item, index) => {
                        return {
                            alias: "front_image" + index,
                            fileName: "base64_image_" + index,
                            format: item.format,
                            name: "front" + index,
                            url: uploadRes[index].url
                        }
                    })
                    editorStore.save('uploadedImages', JSON.stringify(previewImages))
                    setUploaderOpen(false)

                    const otherImgs = uploadRes.splice(canvasList.length)
                    const full_color_set = designImg.map((item, index) => {
                        return {
                            colorId: color_id.current || productStore.colorOptions[0].id,
                            colorName: selectedProductColor.name || productStore.colorOptions[0].name,
                            url: uploadRes[index].url,
                            face: 'default',
                        }
                    })
                    const formData = new FormData()
                    formData.append('parent_id', productId)
                    formData.append('color_id', String(color_id.current) || productStore.colorOptions[0].id)
                    formData.append('size_id', String(size_id.current))
                    formData.append('customtee_web', 1 + '')
                    formData.append('full_color_set', JSON.stringify(full_color_set))

                    otherImgs.forEach((item, index: number) => {
                        formData.append(`image${index + 1}`, item.url!)
                    })
                    //todo
                    designImg.forEach((item, index) => {
                        formData.append(`psd_generated_image_${index}`, uploadRes[index].url!)
                    })

                    const url = `https://api.pairdrop.com/api/createCustomTeeProduct/`;
                    const result = await doPost(url, formData, authStore.token);
                    const sku = result['data']['product_sku']
                    const newProductID = result['data']['product_id']
                    const urlTo = `/checkout?productId=${productId}&sku=${sku}&quantity=${quantity}&size=${size_id.current}&colorId=${color_id.current}`
                    editorStore.purchaseURLAfterUpload = urlTo
                    editorStore.setNewCreatedProductId(newProductID)
                    editorStore.save('purchase_url', urlTo)
                    setConfirmOpen(true)

                }}
                onDelete={removeItem}
                onUndo={() => {

                    undo()
                }}
            />

            <Box h="100%" flexDirection="row" flexWrap>
                <Box h={['80%', '80%', '80%', '100%', '100%']} w={['100%', '100%', '100%', '20%', '20%']} flexDirection="column" toCenterX>
                    <Box w="96%" mt5>
                        <Button
                            onClick={() => {
                                setOpen(true)
                                setActive(0)
                            }}
                            style={{
                                width: '100%',
                                height: '70px',
                                background: active === 0 ? '#518DD9' : '#FFF',
                                borderRadius: '15px',
                                boxShadow: '0px 3px 6px 1px rgba(0,0,0,0.16)',
                                fontSize: '16px',
                                color: active === 0 ? '#fff' : '#373737',
                                display: 'flex',
                                flexDirection: 'column',
                                fontWeight: 'bold',
                            }}>
                            <div>Upload</div>
                            <CloudUploadOutlinedIcon />
                        </Button>

                        <Box flexDirection="row" justifyContent="space-between" flex="1" w="100%" mt5>
                            <Button style={getCommonButtonSty(1)} onClick={() => {
                                setActive(1)
                            }}>
                                <img src="/icons/icon-font.png" alt='font' style={{ marginRight: '10px' }} />
                                Add Text

                            </Button>

                            <Button style={getCommonButtonSty(2)} onClick={() => {
                                setActive(2)
                            }}>
                                <img src="/icons/icon-clothers.png" alt='clothers' style={{ marginRight: '10px' }} />
                                Product
                            </Button>
                        </Box>

                    </Box>
                    {
                        active === 1 && (
                            <Box w="100%" flexDirection="column" flex="1" toCenterX bgWhite h="100%"
                                style={{ borderRadius: '14px', margin: '20px 0' }}>
                                <Button variant="outlined"
                                    onClick={() => addText()}
                                    style={{
                                        borderRadius: '28px',
                                        width: '80%',
                                        height: '55px',
                                        marginTop: '20px',
                                    }}>
                                    <AddIcon style={{ width: '34px', height: '34px' }} />
                                </Button>

                                <div style={title}>Font</div>
                                <Select
                                    onChange={(e: any) => {
                                        setFont(e.target.value)
                                        const activeObj = canvasList[currentCanvasIndex].fabricCanvas.getActiveObject()
                                        if (activeObj && activeObj instanceof fabric.Textbox) {
                                            activeObj.set({ fontFamily: e.target.value })

                                            canvasList[currentCanvasIndex].fabricCanvas.renderAll()
                                        }
                                        commandHandle()
                                    }}
                                    sx={{
                                        mt: 1,
                                        mb: 2,
                                        fontFamily: font,
                                    }}
                                    value={font || SUPPORTED_FONTS[0]}
                                    style={{ width: '80%', marginTop: '10px' }}>
                                    {SUPPORTED_FONTS.map((f) => {
                                        return (
                                            <MenuItem
                                                key={f}
                                                value={f}
                                                sx={{
                                                    fontFamily: f,
                                                }}
                                            >
                                                {f}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>

                                <div style={title}>Color</div>
                                <div style={{ display: 'flex', width: '80%', marginTop: '10px' }}>
                                    <ColorPicker
                                        selectedColor={fontColor}
                                        defaultValue={fontColor}
                                        onChangeComplete={(color) => {
                                            setFontColor(color)
                                            const activeObj = canvasList[currentCanvasIndex].fabricCanvas.getActiveObject()
                                            if (activeObj && activeObj instanceof fabric.Textbox) {
                                                activeObj.set({ fill: color })
                                                commandHandle()
                                                canvasList[currentCanvasIndex].fabricCanvas.renderAll()
                                            }

                                        }}
                                    />
                                </div>
                            </Box>
                        )
                    }

                    {active === 2 && (
                        <Box w="100%" flexDirection="row" flex="1" flexWrap="wrap" alignContent="flex-start" bgWhite
                            h="100%" style={{ borderRadius: '14px', margin: '20px auto', padding: '20px' }}>
                            <div style={title}>Color</div>
                            <div style={{ display: 'flex', width: '80%', marginTop: '15px' }}>
                                <CirclePicker
                                    colors={productStore.colorOptions}
                                    selectedColor={selectedProductColor}
                                    circleSize={27}
                                    colorWheel={false}
                                    onChangeComplete={(color) => {
                                        color_id.current = color.id
                                        setSelectedProductColor(color)
                                        foxpsdObj.current.command({
                                            layers: 'colour',
                                            color: color.value
                                        })
                                    }}
                                />
                            </div>

                            <div style={title}>Size</div>
                            <SizePicker
                                defaultChecked={size_id.current}
                                getSizeHandle={(val) => size_id.current = val}
                                sizeOptions={productStore.sizeOptions}
                            />
                            <div style={title}>Quantity</div>

                            <TextField
                                label="Quantity"
                                value={quantity}
                                type='number'
                                style={{ marginTop: '15px' }}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    const value = event.target.value
                                    if (Number(value) < 0) return
                                    setQuantity(Number(event.target.value));
                                }}
                            />
                        </Box>
                    )}

                    <UploadDialog open={open} handleUpload={(e) => handleFileChange(e)} handleClose={() => {
                        setOpen(false)
                        setActive(-1)
                    }} />
                </Box>

                <Box flexDirection="column" w={['100%', '100%', '100%', '40%', '40%']} h="100%" p2>
                    <Box w={['100%', '100%', '100%', '100%', '100%']} flexDirection="column" bgWhite toCenter>
                        <Box
                            shadowInner
                            relative
                            h='100%'
                            w='100%'
                            column
                            toBetween
                            toCenterX
                            bg='#F4F6F7'
                            flexDirection="column"
                            flex="1"
                        >
                            <img src='/images/icon-downland.png' width='36' height='36' alt='downland'
                                onClick={async () => {
                                    const rs = await foxpsdObj.current.canvasToImg(selectedImg)
                                    await downlandImages(rs[0])
                                }}
                                style={{
                                    position: 'absolute',
                                    zIndex: '999',
                                    left: '20px',
                                    top: '20px',
                                    cursor: 'pointer'
                                }} />
                            <div style={{ width: '100%', position: 'relative' }} id="xiaoguotu">
                            </div>
                        </Box>
                    </Box>

                    <Box flexDirection="column" alignItems="center" justifyContent="center" w="100%" style={{ flex: '1' }} bgWhite>
                        <ImageCarousel selected={selectedImg} images={designImg} imgClickHandle={(index) => {
                            foxpsdObj.current.carousel.slide(index)
                            setSelectedImg(index)
                        }} />

                    </Box>
                </Box>

                <Box
                    h={['100%', '100%', '100%', '100%']}
                    w={['100%', '100%', '100%', '40%', '40%']}
                    column
                    toCenterX
                    toBetween
                    bgWhite
                    mt={['35%', '35%', '35%', '0', '0']}
                >
                    <Box
                        w="100%"
                        h="100%"
                        bgWhite
                        column
                        toCenterX
                        top="20vh"
                        top0--1g
                        overflowAuto
                    >
                        <Box flexDirection="row" w="100%" p3 relative={true}>
                            <Box
                                style={{
                                    position: 'relative'
                                }}
                                flexDirection="column" alignItems="flex-start">
                                <Button style={btnActive === 0 ? styles.activeBtn : styles.commonBtn}
                                    onClick={() => {
                                        setBtnActive(pre => {
                                            return pre === 0 ? 1 : 0
                                        })
                                    }}>
                                    Position
                                </Button>

                                {
                                    btnActive === 0 && <Box className='positionBox'
                                        style={{
                                            position: 'absolute',
                                            top: '40px'
                                        }}
                                        flexDirection="row">
                                        <img src="/icons/position1.png" alt='position'
                                            onClick={() => alignSelectedObjects('center', 'vertical')}
                                            style={{ width: '37px', height: '37px' }} />
                                        <img src="/icons/position2.png" alt='position'
                                            onClick={() => alignSelectedObjects('center', 'horizontal')}
                                            style={{ width: '37px', height: '37px', marginLeft: '5px' }} />
                                        <img src="/icons/position3.png" alt='position'
                                            onClick={() => alignSelectedObjects('left', 'horizontal')}
                                            style={{ width: '37px', height: '37px', marginLeft: '5px' }} />
                                        <img src="/icons/position4.png" alt='position'
                                            onClick={() => alignSelectedObjects('right', 'horizontal')}
                                            style={{ width: '37px', height: '37px', marginLeft: '5px' }} />
                                        <img src="/icons/position5.png" alt='position'
                                            onClick={() => alignSelectedObjects('top', 'vertical')}
                                            style={{ width: '37px', height: '37px', marginLeft: '5px' }} />
                                        <img src="/icons/position6.png" alt='position'
                                            onClick={() => alignSelectedObjects('bottom', 'vertical')}
                                            style={{ width: '37px', height: '37px', marginLeft: '5px' }} />
                                    </Box>
                                }
                            </Box>

                            {/* <Box flexDirection="column">
                                <Button style={styles.commonBtn}
                                    onClick={() => {
                                        setBtnActive(1)
                                        removeActiveObject()
                                    }
                                    }>
                                    Remove Background
                                </Button>
                            </Box> */}

                            <div style={{ position: 'absolute', right: '10px', top: '10px', zIndex: '999' }}>

                                <button
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        background: 'none'
                                    }}
                                    onClick={() => setIsLayerPanelVisible(!isLayerPanelVisible)}>
                                    <span style={{ fontWeight: 'bold', paddingRight: '10px' }}>Layer</span>{isLayerPanelVisible ? '▲' : '▼'}
                                </button>
                                {isLayerPanelVisible && (
                                    <Box w="144px" bgWhite className='LayerPaneDialog' style={{ border: '1px solid #707070' }}>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            borderBottom: '1px solid #707070',
                                            justifyContent: 'space-around',
                                            padding: '4px'
                                        }}>

                                            <img src='/icons/icon-move.png' alt='move' style={{ width: '13px', height: '13px' }} />

                                            {isLayerLocked ?
                                                <img alt='lock' onClick={() => {
                                                    toggleLockLayer(selectedLayer!)
                                                }} src='/icons/icon-lock.png' style={{ ...btnIconStyle, width: '16px', height: '16px' }} />
                                                : <img src='/icons/icon-unlock.png' alt='unlock' onClick={() => {
                                                    toggleLockLayer(selectedLayer!)
                                                }} style={{ width: '16px', height: '16px' }} />}

                                            <span style={{ fontSize: '8px', marginLeft: '10px' }}>Opacity:</span>
                                            <input
                                                type="number"
                                                min="0"
                                                max="100"
                                                value={layerList[currentCanvasIndex] ? layerList[currentCanvasIndex].find(item => item.id == selectedLayer)?.opacity! * 100 || 100 : 100}
                                                onChange={(e) =>
                                                    handleOpacityChange(
                                                        selectedLayer!,
                                                        parseFloat(e.target.value) / 100
                                                    )
                                                }
                                                style={{ width: '50px', marginLeft: '10px', border: '1px solid #707070' }}
                                                disabled={!selectedLayer}
                                            />
                                        </div>

                                        <div id="sortBox">
                                            {layerList[currentCanvasIndex] && layerList[currentCanvasIndex].map((item: any, index) => (

                                                <div
                                                    key={item.id}
                                                    data-id={item.id}
                                                    className='sortItem'
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        borderBottom: '1px solid #707070',
                                                        width: '100%', // Ensuring all items have the same width
                                                        height: '35px',
                                                        background: selectedLayer === item.id ? '#E4E4E4' : 'none',
                                                        boxSizing: 'border-box', // Ensuring padding and border are included in width
                                                    }}
                                                >
                                                    <button
                                                        style={{ borderRight: '1px solid #707070', background: 'none', height: '100%', width: '33px' }}
                                                        onClick={() => toggleLayerVisibility(item.id)}>
                                                        {item.visible ? <img src='/icons/icon-eye.png' alt='eye' style={btnIconStyle} /> : <img src='/icons/icon-no-eye.png' alt='no eye' style={{ width: '15px', height: '15px' }} />}
                                                    </button>
                                                    <span
                                                        onClick={() => handleLayerClick(item.id)}
                                                        style={{
                                                            cursor: 'pointer',
                                                            textDecoration: selectedLayer === item.id ? 'underline' : 'none',
                                                            marginLeft: '10px',
                                                            fontSize: '10px'
                                                        }}
                                                    >
                                                        {isTextObject(item) ? <Box flex alignItems="center">
                                                            <img src="/icons/icon-txt.png" alt='icon' style={{ width: '13px', height: '14px' }} />
                                                            <span style={{ width: '60px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.text}</span>
                                                        </Box> : <Box flex alignItems="center">
                                                            <img src={item._element.currentSrc} alt='icon' style={{ width: '14px', height: '14px' }} />
                                                            <span style={{ width: '60px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>layer
                                                                <span style={{ marginLeft: '5px' }}>{index}</span>
                                                            </span>
                                                        </Box>}
                                                    </span>

                                                    <img src='/icons/icon-movePlus.png' alt='icon' style={{ width: '14px', height: '14px', cursor: 'move', marginLeft: '10px' }} />
                                                </div>
                                            ))}

                                            <div

                                                className='sortItem1'
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    borderBottom: '1px solid #707070',
                                                    width: '100%', // Ensuring all items have the same width
                                                    height: '35px',
                                                    background: selectedLayer === 'background' ? '#E4E4E4' : 'none',
                                                    boxSizing: 'border-box', // Ensuring padding and border are included in width
                                                }}
                                            >
                                                <button
                                                    style={{ borderRight: '1px solid #707070', background: 'none', height: '100%', width: '33px' }}
                                                    onClick={() => toggleLayerVisibility('background')}>
                                                    {backgroundColorList[currentCanvasIndex] && (backgroundColorList[currentCanvasIndex].visible ? <img src='/icons/icon-eye.png' style={btnIconStyle} alt='icon' /> : <img src='/icons/icon-no-eye.png' style={{ width: '15px', height: '15px' }} />)}
                                                </button>
                                                <span
                                                    onClick={() => handleLayerClick('background')}
                                                    style={{
                                                        cursor: 'pointer',
                                                        textDecoration: selectedLayer === 'background' ? 'underline' : 'none',
                                                        marginLeft: '10px',
                                                        fontSize: '10px'
                                                    }}
                                                >
                                                    <Box flex alignItems="center">
                                                        {
                                                            backgroundColorList[currentCanvasIndex] && (backgroundColorList[currentCanvasIndex].color === 'transparent' ? <img src="/icons/icon-transport.png" style={{ width: '16px', height: '13px' }} /> : <span style={{ width: '16px', height: '13px', border: '1px solid #707070', background: backgroundColorList[currentCanvasIndex].color }}></span>)

                                                        }
                                                        <span>background</span>
                                                    </Box>
                                                </span>
                                            </div>
                                        </div>

                                    </Box>
                                )}
                            </div>


                        </Box>

                        <Box flexDirection="row" w="100%" h="80%" pt10 style={{ marginTop: '10px', paddingLeft: '25px' }}>
                            <ColorPickerColumn
                                selectedColor={backgroundColorList[currentCanvasIndex]?.color}
                                onChangeComplete={(color) => {

                                    if (backgroundColorList[currentCanvasIndex].visible) {
                                        const canvas = canvasList[currentCanvasIndex].fabricCanvas
                                        canvas.setBackgroundColor(color, canvas.renderAll.bind(canvas))
                                        setBackgroundColorList(pre => {
                                            return {
                                                ...pre,
                                                [currentCanvasIndex]: {
                                                    color,
                                                    visible: pre[currentCanvasIndex].visible
                                                }
                                            }
                                        })
                                    }
                                    commandHandle()
                                }} />
                            <div style={canvasStyleObj.bookList_body} className='bookListBody'>
                                {
                                    canvasList.map((_, canvasIndex) => {
                                        return (
                                            <div key={canvasIndex} style={{ ...canvasStyleObj.canvasBox, display: canvasIndex === currentCanvasIndex ? 'block' : 'none', backgroundImage: "url('/images/er.png')", overflow: 'hidden' }} id={`canvasBox${canvasIndex}`}>
                                                <canvas id={'fabricCanvas' + canvasIndex}></canvas>
                                                <img className={'overlayImage' + canvasIndex} style={{ position: 'absolute', top: '0', left: '0', pointerEvents: 'none' }} src={maskList[currentCanvasIndex].image} alt="Overlay" />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Box>

                        <Box
                            w="100%"
                            flex
                            row
                            flexWrap="wrap"
                            boxSizing="border-box"
                            p1
                        >
                            {
                                maskList.map((item, index) => {
                                    return (
                                        <Box
                                            onClick={() => {
                                                setCurrentCanvasIndex(index)
                                                currentIndex.current = index
                                                canvasList.forEach((canvas) => {
                                                    canvas.fabricCanvas.discardActiveObject()// 取消当前选中的对象，包括多选和组选择
                                                    canvas.fabricCanvas.renderAll()
                                                })
                                            }}
                                            key={index}
                                            border="1px solid #707070"
                                            color="#707070"
                                            style={{
                                                borderRadius: '12px',
                                                fontSize: isLargeScreen ? '18px' : '12px',
                                                padding: isLargeScreen ? ' 6px 20px' : '2px 12px',
                                                cursor: 'pointer',
                                                background: currentCanvasIndex === index ? '#518DD9' : '#fff',
                                                border: currentCanvasIndex === index ? 'none' : '1px solid #707070',
                                                color: currentCanvasIndex === index ? '#fff' : '#707070',
                                            }}
                                            flex
                                            row
                                            toCenter
                                            mr1
                                            mt1
                                        >
                                            {capitalizeFirstLetter(item.comment)}
                                        </Box>
                                    )
                                })
                            }
                        </Box>
                    </Box>
                </Box>
            </Box>

            <ConfirmDialog
                onAction={onUploadAction}
                open={confirmOpen}
                handleClose={() => { }}
                handleConfirm={() => { }}
            />

            <UploaderDialog
                open={uploaderOpen}
                cancelClose={() => { }}
                progress={progress}
            />

            <UploaderModal
                showUpload={editorStore.showUpload}
                uploadError={editorStore.uploadError}
                uploading={editorStore.uploading}
                uploadingProgress={editorStore.uploadingProgress}
                onAction={onUploadAction}
            />
            <LoginModal
                isOpen={authStore.showLoginModalOnEditor}
                onClose={() => {
                    authStore.setShowLoginModalOnEditor(false)
                }}
                refreshAfter={false}
            />
            <SetProductPropsModal
                isOpen={showEditProduct}
                product={newProduct as MyProductItemVO}
                hasImage={true}
                canShopify={authStore.hasBindedShop}
                onClose={() => {
                    setShowEditProduct(false)
                }}
                onSubmit={async (title, desc, price, toCT, toShopify, shopTo) => {
                    const newItem: MyProductItemVO = {
                        ...newProduct!,
                        name: title,
                        descShorten: desc,
                        price: price.toString(),
                        onSaleCustomtee: toCT,
                        onSaleShopify: toShopify,
                    }
                    await productStore.sellProduct(
                        authStore.token,
                        newItem?.id!,
                        title,
                        desc,
                        toCT,
                        price,
                    )
                    if (toShopify && shopTo) {
                        await updateShopifyProducts(newItem?.id!, shopTo, 'add')
                        setShowEditProduct(false)
                        setTimeout(() => {
                            navigator('/dashboard')
                        }, 2000);
                    } else {
                        setShowEditProduct(false)
                        navigator('/dashboard')
                    }
                }}
            />
            <ProgressIndicator
                isOpen={showProgress}
                text="Publishing to Shopify"
                loading={updating}
                message={message}
                messageType={messageType}
                onClose={() => {
                    setShowProgress(false)
                }}
            />
        </Box >
    )
})

const Editor: FC = () => {
    return (
        <EditorProvider>
            <AssembledEditor />
        </EditorProvider>
    )
}
export default Editor
