import { Box } from "@fower/react";
import { FC, useCallback, useEffect, useState } from "react";
import HomeHeader from "../home/Header";
import { LoginMode } from "../../TypeDeclare";
import LoginModal from "../../common/LoginModal";
import LinearProgress from '@mui/material/LinearProgress';
import DetailPane from "./components/DetailPane";
import { EditorProvider } from "../editor/store/editor/useEditorStore";
import { useProductStore } from "../product_list/store/useProdustListStore";
import { useNavigate, useParams } from "react-router-dom";

const AssembledProductDetail: FC = () => {
    const productStore = useProductStore()
    const navigator = useNavigate()
    const productId = useParams().id

    const [showLogin, setShowLogin] = useState<boolean>(false)
    const [preMode, setPreMode] = useState<LoginMode>('signup')

    const [loading, setLoading] = useState<boolean>(true)

    const reloadProduct = useCallback(async () => {
        if (productId !== undefined) {
            setLoading(true)
            const p = await productStore.loadSingleProdcut(productId)
            productStore.setProduct(p)
            setLoading(false)
        }
    }, [productStore, productId])

    useEffect(() => {
        if (productStore.sizeOptions.length > 0) {
            const defaultSize = productStore.sizeOptions[0]
            productStore.setSize(defaultSize)
        }
    }, [productStore, productStore.sizeOptions])

    useEffect(() => {
        reloadProduct().then(null)
    }, [productId])

    return (
        <Box w="100%" toCenterX column>
            <HomeHeader
                fullWidth={false}
                page={'home'}
                onLogin={(mode: LoginMode) => {
                    setPreMode(mode)
                    setShowLogin(true)
                }}
                onShowMenu={() => { }}
            />

            {loading && <Box w="100%">
                <LinearProgress />
            </Box>}


            {
                !loading && <>
                    <Box w="80%" flex alignItems="center" style={{ cursor: 'pointer' }} onClick={() => navigator(-1)}>
                        <img src="/icons/icon-back.png" alt="back" style={{ width: '32px', height: '32px' }} />
                        <span style={{ fontSize: '20px', marginLeft: '5px' }}>BACK</span>
                    </Box>
                    <DetailPane /></>
            }

            {/* {
                !loading && <DetailFooter />
            } */}




            <LoginModal
                isOpen={showLogin}
                refreshAfter={false}
                redirectToDashboardAfter={true}
                preMode={preMode}
                onClose={() => {
                    setShowLogin(false)
                }}
            />
        </Box>
    )
}
const ProductDetail: FC = () => {
    return (
        <EditorProvider>
            <AssembledProductDetail />
        </EditorProvider>
    )
}
export default ProductDetail;