import { Box } from '@fower/react'
import {
  PayPalButtons,
  PayPalButtonsComponentProps,
} from '@paypal/react-paypal-js'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { resetCTKey } from '../../common/StringUtils'
import {
  capturePaymentAPI,
  createPaymentAPI,
} from '../product_list/services/productService'
import { useCheckoutStore } from './store/checkout/useCheckoutStore'
import ReactGA from 'react-ga'
import { useAuthStore } from '../../store/useAuthStore'

export type PaypalButtonWrapperProps = {
  amount: string
  currency: string
  disabled: boolean
  onValidate: () => boolean
  onComplete: (success: boolean) => void
}
export const PaypalButtonWrapper: FC<PaypalButtonWrapperProps> = observer(
  ({ disabled, onComplete, onValidate }) => {
    const buttonStyle: PayPalButtonsComponentProps['style'] = {
      layout: 'vertical',
    }
    const coStore = useCheckoutStore()
    const authStore = useAuthStore()
    
    return (
      <Box w="100%" mt5>
        <PayPalButtons
          style={buttonStyle}
          disabled={disabled}
          onClick={async (_data, actions) => {
            const passed = onValidate()
            if (!passed) {
              return actions.reject()
            }
            return actions.resolve()
          }}
          createOrder={async (_data) => {
            return new Promise((resolve) => {
              createPaymentAPI(coStore.order, authStore.token).then(
                (result) => {
                  console.log('createOrder',result.data)
                  resolve(result.data.id)
                },
              )
            })
          }}
          onApprove={async (data) => {
            const result = await capturePaymentAPI(
              { orderID: data.orderID },
              authStore.token,
            )
            console.log('onApprove',data)
            resetCTKey()
            ReactGA.event({ category: 'Checkout', action: 'completed' })
            onComplete(result.success)
          }}
          onError={(err) => {
            ReactGA.event({
              category: 'Checkout',
              action: 'error',
              label: JSON.stringify(err),
            })
          }}
          onCancel={(data) => {
            ReactGA.event({
              category: 'Checkout',
              action: 'cancel',
              label: JSON.stringify(data),
            })
          }}
        />
      </Box>
    )
  },
)
