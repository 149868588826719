import { FC, useCallback, useEffect, useState } from 'react'
import { Box } from '@fower/react'
import { EditorProvider, useEditorStore } from './store/editor/useEditorStore'
import { observer } from 'mobx-react-lite'
import EditorCanvas from './components/center/EditorCanvas'
import AddElementPanel from './components/right/AddElementPanel'
import { useProductStore } from '../product_list/store/useProdustListStore'
import {
  LinkedShop,
  MyProductItemVO,
  ProductItemVO,
  ShopifyActionType,
} from '../../TypeDeclare'
import { getURLParam } from '../../common/StringUtils'
import LoginModal from '../../common/LoginModal'
import { useAuthStore } from '../../store/useAuthStore'
import UploaderModal, { UploadAction } from './UploaderModal'
import { useNavigate } from 'react-router-dom'
import SetProductPropsModal from '../../common/SetProductPropsModal'
import ProgressIndicator from '../../common/ProgressIndicator'
import { AlertColor } from '@mui/material'
import { useDashbardStore } from '../dashboard/store/useDashboardStore'
import { LiveChatWidget } from '@livechat/widget-react'

const AssembledEditor: FC = observer(() => {
  const productStore = useProductStore()
  const editorStore = useEditorStore()
  const authStore = useAuthStore()
  const dashboardStore = useDashbardStore()
  const navigator = useNavigate()
  const productId = getURLParam('productId')
  const [product, setProduct] = useState<ProductItemVO | undefined>(undefined)
  const [newProduct, setNewProduct] = useState<ProductItemVO | undefined>(
    undefined,
  )
  const [showEditProduct, setShowEditProduct] = useState<boolean>(false)
  const [showProgress, setShowProgress] = useState<boolean>(false)
  const [updating, setUpdating] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [messageType, setMessageType] = useState<AlertColor>('info')
  const [loadingProduct, setLoadingProduct] = useState<boolean>(true)
  

  const reloadProduct = useCallback(async () => {
    if (productId !== undefined) {
      setLoadingProduct(true)
      const p = await productStore.loadSingleProdcut(productId)
      setProduct(p)
      setLoadingProduct(false)
    }
  }, [productStore, productId])

  const reloadNewCreatedProduct = useCallback(async () => {
    if (editorStore.newCreatedProductId === '') return
    if (editorStore.newCreatedProductId !== undefined) {
      const p = await productStore.loadSingleProdcut(
        editorStore.newCreatedProductId,
      )
      setNewProduct(p)
    }
  }, [productStore, editorStore])

  const updateShopifyProducts = useCallback(
    async (
      productId: string,
      selectedShop: LinkedShop,
      action: ShopifyActionType,
    ) => {
      setShowProgress(true)
      setUpdating(true)
      const result = await dashboardStore.updateShopifyProduct(
        authStore.token,
        selectedShop.ctToken!,
        productId,
        action,
      )
      setUpdating(false)
      if (result) {
        if (action === 'add') {
          setMessage('Product added to Shopify')
          setMessageType('success')
        } else {
          setMessage('Product removed from Shopify')
          setMessageType('success')
        }
      } else {
        setMessage('Error')
        setMessageType('error')
      }
    },
    [authStore.token, dashboardStore],
  )

  useEffect(() => {
    reloadNewCreatedProduct().then(null)
  }, [editorStore.newCreatedProductId, reloadNewCreatedProduct, reloadProduct])
  useEffect(() => {
    reloadProduct().then(null)
  }, [productId, reloadProduct])

  useEffect(() => {
    authStore.loadUserProfile().then((result) => {
      dashboardStore.setLinkedShops(result.rawLinkedShops)
    })
  }, [authStore, dashboardStore])

  const onUploadAction = (action: UploadAction) => {
    if (action === 'retry') {
      editorStore.resetUpload()
      editorStore.closeUpload()
    } else if (action === 'cancel') {
      global.location.reload()
    } else if (action === 'buy') {
      editorStore.closeUpload()
      navigator(editorStore.purchaseURLAfterUpload)
    } else if (action === 'dashboard') {
      editorStore.closeUpload()
      navigator('/dashboard')
    } else if (action === 'sell') {
      editorStore.closeUpload()
      reloadProduct().then(null)
      setShowEditProduct(true)
    }
  }
  return (
    <Box h="100vh" flexDirection={['column', 'column', 'column', 'row', 'row']}>
      <EditorCanvas product={product} />
      <AddElementPanel product={product} loadingProduct={loadingProduct}/>
      <UploaderModal
        showUpload={editorStore.showUpload}
        uploadError={editorStore.uploadError}
        uploading={editorStore.uploading}
        uploadingProgress={editorStore.uploadingProgress}
        onAction={onUploadAction}
      />
      <LoginModal
        isOpen={authStore.showLoginModalOnEditor}
        onClose={() => {
          authStore.setShowLoginModalOnEditor(false)
        }}
        refreshAfter={false}
      />
      <SetProductPropsModal
        isOpen={showEditProduct}
        product={newProduct as MyProductItemVO}
        hasImage={true}
        canShopify={authStore.hasBindedShop}
        onClose={() => {
          setShowEditProduct(false)
        }}
        onSubmit={async (title, desc, price, toCT, toShopify, shopTo) => {
          const newItem: MyProductItemVO = {
            ...newProduct!,
            name: title,
            descShorten: desc,
            price: price.toString(),
            onSaleCustomtee: toCT,
            onSaleShopify: toShopify,
          }
          await productStore.sellProduct(
            authStore.token,
            newItem?.id!,
            title,
            desc,
            toCT,
            price,
          )
          if (toShopify && shopTo) {
            await updateShopifyProducts(newItem?.id!, shopTo, 'add')
            setShowEditProduct(false)
            setTimeout(() => {
              navigator('/dashboard')
            }, 2000)
          } else {
            setShowEditProduct(false)
            navigator('/dashboard?section=Products')
          }
        }}
      />
      <ProgressIndicator
        isOpen={showProgress}
        text="Publishing to Shopify"
        loading={updating}
        message={message}
        messageType={messageType}
        onClose={() => {
          setShowProgress(false)
        }}
      />
      <LiveChatWidget license="15929757" />
    </Box>
  )
})

const Editor: FC = () => {
  return (
    <EditorProvider>
      <AssembledEditor />
    </EditorProvider>
  )
}
export default Editor
