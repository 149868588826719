import { doGet, doPost } from '../../../common/commonService';

export const getCategories = async (token: string) => {
  const url = 'https://www.customtee.co/api/productCategories/';
  return doGet(url, {}, token);
};

export const getTags = async (token: string) => {
  const url = 'https://www.customtee.co/api/tags/';
  return doGet(url, null, token);
};

export const getOriginalProducts = async (token: string, page: number) => {
  const url = 'https://www.customtee.co/api/myUploadedRawProducts/';
  return doGet(url, { page }, token);
};

export const getSNC = async (token: string) => {
  const url = 'https://www.customtee.co/api/productAttributes/';
  return doGet(url, {}, token);
};

export const uploadOriginalProduct = async (
  token: string,
  formData: FormData
) => {
  const url = 'https://www.customtee.co/api/rawProductUpload/';
  return doPost(url, formData, token);
};

export const updateOriginalProduct = async (
  id: string,
  token: string,
  formData: FormData
) => {
  const url = `https://www.customtee.co/api/rawProductUpdate/${id}/`;
  return doPost(url, formData, token);
};
