import { Box } from '@fower/react'
import { FC, useEffect, useState } from 'react'
import { FooterElement, FooterElements } from '../../TypeDeclare'
import InstagramIcon from '@mui/icons-material/Instagram'

const FooterSection: FC<{ group: string; items: FooterElement[] }> = ({
    group,
    items,
}) => {
    return (
        <Box h="300px" w="240px" p5 column toLeft>
            <Box white mb10 toLeft>
                {group}
            </Box>
            {items.map((item) => {
                if (item.action === 'icon') {
                    return (
                        <Box
                            cursorPointer
                            onClick={() => {
                                global.location.href = item.data
                            }}
                            key={item.name}
                        >
                            <InstagramIcon
                                sx={{
                                    color: 'rgba(255,255,255,0.6)',
                                }}
                            />
                        </Box>
                    )
                }
                return (
                    // <Button

                    //   onClick={()=>{
                    //       global.location.href = item.data;
                    //   }}
                    //   key={item.name}
                    // >
                    //   {item.name}
                    // </Button>
                    <Box
                        cursor="pointer"
                        color="rgba(255,255,255,0.6)"
                        key={item.name}
                        pt4
                        onClick={() => {
                            global.location.href = item.data
                        }}
                    >
                        {item.name}
                    </Box>
                )
            })}
        </Box>
    )
}

const HomeFooter: FC = () => {
    const getCV = () => {
        const remain = global.innerWidth - (global.innerWidth % 240)
        return `${remain}px`
    }
    const [theW, setTheW] = useState<string>(getCV())
    useEffect(() => {
        global.addEventListener('resize', (e) => {
            setTheW(getCV())
        })
    }, [])
    const itemsInGroup = (group: string): FooterElement[] => {
        return FooterElements.filter((e) => {
            return e.group === group
        })
    }
    // 'OUR COMPANY' | 'SUPPORT' | 'MERCHANTS' | 'FOLLOW ME'
    const groups = [
        { group: 'OUR COMPANY', items: itemsInGroup('OUR COMPANY') },
        { group: 'SUPPORT', items: itemsInGroup('SUPPORT') },
        { group: 'MERCHANTS', items: itemsInGroup('MERCHANTS') },
        { group: 'FOLLOW ME', items: itemsInGroup('FOLLOW ME') },
    ]
    return (
        <Box bg="#4384D6" w="100%" toCenter column>
            <Box flex row flexNowrap--md toCenter--md flexWrap="wrap" w={theW}>
                {groups.map((g, i) => {
                    return (
                        <FooterSection key={i.toString()} group={g.group} items={g.items} />
                    )
                })}
            </Box>
        </Box>
    )
}

export default HomeFooter
