import './App.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import ReactGA from 'react-ga'
import { useEffect } from 'react'
import OrderList from './pages/order_list/OrderList';

import NewEditor from './pages/editor/NewEditor'
import { createTheme } from '@mui/material'
import { ThemeProvider } from '@emotion/react'
import { ProductListProvider } from './pages/product_list/store/useProdustListStore'
import { DashboardProvider } from './pages/dashboard/store/useDashboardStore'
import { AuthProvider } from './store/useAuthStore'
import ProductDetail from './pages/productDetail';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';


import Home from './pages/home/Home'
import DashBoard from './pages/dashboard/Dashboard'
import Editor from './pages/editor/Editor'
import Checkout from './pages/checkout/Checkout'
import ProductList from './pages/product_list/ProductList'
import Connect from './pages/connect/Connect'
import NotFound from './pages/notfound/NotFound'

const stripePromise = loadStripe('pk_live_dRgH33f91P2rI3c0z2UGIOXM');
const theme = createTheme({
    palette: {
        primary: {
            main: '#4384D6',
        },
        secondary: {
            main: '#000000',
        },
    },
    typography: {
        fontFamily: ['Avenir Medium', 'Roboto', '"Helvetica Neue"'].join(','),
        button: {
            textTransform: 'capitalize',
        },
        h4: {
            fontFamily: 'Avenir Medium',
            fontWeight: 500,
            fontSize: '18px',
        },
        h5: {
            fontFamily: 'Avenir Heavy',
            fontWeight: 800,
            fontSize: '20px',
        },
        h6: {
            fontFamily: 'Avenir Medium',
            fontWeight: 500,
            fontSize: '14px',
        },
        h3: {
            fontFamily: 'Avenir-Heavy',
            fontWeight: 800,
            fontSize: '18px',
        },
    },
    components: {
        MuiSlider: {
            styleOverrides: {
                thumb: {
                    backgroundColor: '#fff',
                    border: '2px solid #6E8FA0',
                },
            },
        },
    },
})

function App() {
    useEffect(() => {
        ReactGA.initialize('UA-78117459-5')
    }, [])
    return (
        <div>
            <Elements stripe={stripePromise}>
                <ThemeProvider theme={theme}>
                    <AuthProvider>
                        <ProductListProvider>
                            <DashboardProvider>
                                <BrowserRouter>
                                    <Routes>
                                        <Route path="/" element={<Home />} />
                                        <Route path="/oldEditor" element={<Editor />} />
                                        <Route path="/editor" element={<NewEditor />} />
                                        <Route path="/detail/:id/:name" element={<ProductDetail />} />

                                        <Route path="/checkout" element={<Checkout />} />
                                        <Route path="/catalog/:section/:product" element={<ProductList />} />
                                        <Route path="/catalog/:section/" element={<ProductList />} />
                                        <Route path="/catalog" element={<ProductList />} />
                                        <Route path="/dashboard" element={<DashBoard />} />
                                        <Route path="/connect" element={<Connect />} />
                                        <Route path='/orders' element={<OrderList />} />
                                        <Route path="*" element={<NotFound />} />
                                    </Routes>
                                </BrowserRouter>
                            </DashboardProvider>
                        </ProductListProvider>
                    </AuthProvider>
                </ThemeProvider>
            </Elements>
        </div>
    )
}

export default App
