import { Box } from '@fower/react'
import { FC } from 'react'
import SideBarTab from '../../common/SideBarTab'
import { useAuthStore } from '../../store/useAuthStore'

export type SideBarNames =
  | 'Home'
  | 'Products'
  | 'Stores'
  | 'Account'
  | 'Orders'
  | 'Payments'
  | 'Upload Product'
const items: Array<{ icon: string; name: SideBarNames }> = [
  { icon: 'home', name: 'Home' },
  { icon: 'order', name: 'Orders' },
  { icon: 'product', name: 'Products' },
  { icon: 'connect', name: 'Stores' },
  { icon: 'payments', name: 'Payments' },
  { icon: 'uploader', name: 'Upload Product' },
  { icon: 'account', name: 'Account' },
]

export type DashboardSideBarProps = {
  currentName: SideBarNames
  onTabChange: (index: number, name: SideBarNames) => void
}

const DashboardSideBar: FC<DashboardSideBarProps> = ({
  currentName,
  onTabChange,
}) => {
  const auth = useAuthStore();
  const excludes: Array<string> = auth.role === 'pm'? [] : ['uploader']
  return (
    <Box column pl2 bgWhite h-100p shadowLarge>
      {items
        .filter((item) => !excludes.includes(item.icon))
        .map((item, index) => {
          return (
            <SideBarTab
              key={index}
              index={index}
              selected={currentName === item.name}
              icon={item.icon}
              name={item.name}
              onClick={(i) => {
                onTabChange(i, item.name)
              }}
            />
          )
        })}
    </Box>
  )
}
export default DashboardSideBar
