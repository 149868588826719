import { Box } from '@fower/react'
import { observer } from 'mobx-react-lite'
import { FC, useCallback, useEffect, useState } from 'react'
import CatalogSelector from './components/CatalogSelector'
import { Button } from '@mui/material'
import HistoryProducts from './components/HistoryProduct'
import {
    OriginalProductProvider,
    useOriginalProductStore,
} from '../store/useOriginalProductStore'
import { useAuthStore } from '../../../store/useAuthStore'
import {
    OriginalProductItemVO,
    PreviewImageItem,
    RawImageElemet,
    StyledPagination,
} from '../../../TypeDeclare'
import SetRawProductPropsModal from '../../../common/SetRawProductPropsModal'

export type CatalogProps = {
    onNext: () => void
}

const Catalog: FC<CatalogProps> = observer(({ onNext }) => {
    const opStore = useOriginalProductStore()
    const authStore = useAuthStore()
    const [selectedItem, setSelectedItem] = useState<
        OriginalProductItemVO | undefined
    >(undefined)
    const [showEditProduct, setShowEditProduct] = useState<boolean>(false)
    const [onLoad, setOnLoad] = useState<boolean>(true)
    const [uploading, setUploading] = useState<boolean>(false)


    const refreshProducts = useCallback(
        (page: number) => {
            opStore.getOriginalProducts(authStore.token, page).then(null)
        },
        [authStore.token, opStore],
    )
    useEffect(() => {
        refreshProducts(1)
        opStore.getSizesNColors(authStore.token)
    }, [authStore.token, opStore, refreshProducts])
    useEffect(() => {
        opStore.getCategories(authStore.token)
        //TODO: remove later
        // opStore.demo()
    }, [authStore.token, opStore])
    useEffect(() => {
        if (opStore.categoryOptionsLevel1.length === 0) {
            setOnLoad(false)
        } else {
            setOnLoad(true)
        }
    }, [opStore.categoryOptionsLevel1])

    const readyToStart =
        opStore.selectedParentCategory !== undefined &&
        opStore.selectedChildCategory !== undefined

    return (
        <OriginalProductProvider>
            <Box w="100%" h="90%" toCenterX column mt2>
                <Box w="100%" h="100%" toBetween row>
                    <Box w="41%" h="100%" borderRight-1 borderGray300 toCenterX column>
                        <Box text3XL fontBold mt10>
                            Create a New Product
                        </Box>
                        <Box w="100%" h="100%" column px4 toCenterX>
                            <CatalogSelector
                                selectedParent={opStore.selectedParentCategory}
                                selectedChild={opStore.selectedChildCategory}
                                onChangeMain={(selectedCategory) => {
                                    if (
                                        selectedCategory.name !==
                                        opStore.selectedParentCategory?.name
                                    ) {
                                        opStore.setSelectedParentCategory(selectedCategory)
                                    }
                                    if (opStore.selectedChildCategory !== undefined) {
                                        opStore.setSelectedChildCategory(undefined)
                                    }
                                }}
                                mainCategories={opStore.categoryOptionsLevel1}
                                onChangeSub={(selectedCategory) => {
                                    if (
                                        selectedCategory.name !==
                                        opStore.selectedChildCategory?.name
                                    ) {
                                        opStore.setSelectedChildCategory(selectedCategory)
                                    }
                                }}
                                subCategories={opStore.categoryOptionsLevel2}
                                onLoad={onLoad}
                            />
                            <Button
                                disabled={!readyToStart}
                                variant="contained"
                                size="large"
                                sx={{ mt: 5, width: 300, height: 45 }}
                                onClick={onNext}
                            >
                                Start Creating
                            </Button>
                        </Box>
                    </Box>
                    <Box
                        w="59%"
                        h="100%"
                        px10
                        borderLeft-1
                        borderGray300
                        toCenterX
                        textXL
                        fontBold
                        column
                    >
                        <Box text3XL fontBold mt10 mb10>
                            Uploaded Products
                        </Box>
                        <HistoryProducts
                            list={opStore.originalProducts}
                            onSelect={(item) => {
                                setShowEditProduct(true)
                                opStore.loadAndParseProduct(item).then((newItem) => {
                                    setSelectedItem(newItem)
                                })
                            }}
                        />
                    </Box>
                </Box>
            </Box>
            <StyledPagination
                count={opStore.originalProductsPageCount}
                page={opStore.originalProductsCurrentPage}
                onChange={(_event: React.ChangeEvent<unknown>, value: number) => {
                    refreshProducts(value)
                }}
                variant="outlined"
                color="primary"
                fixed
                right2
                bottom2
            />
            <SetRawProductPropsModal
                uploading={uploading}
                isOpen={showEditProduct}
                product={selectedItem}
                onClose={() => {
                    setShowEditProduct(false)
                    setSelectedItem(undefined)
                }}
                onUpdateShippingOption={(item, to) => {
                    if (!selectedItem) return
                    const newSos = selectedItem.shippingOptions.map((so) => {
                        if (so.id === item.id) {
                            return {
                                ...so,
                                selected: to,
                            }
                        }
                        return so
                    })
                    setSelectedItem({
                        ...selectedItem,
                        shippingOptions: newSos,
                    })
                }}
                onSubmit={async (
                    title,
                    price,
                    shippingOptions,
                    weight,
                    volume,
                    desc,
                    updatedCoverImage,
                    updatedEditableImage,
                    updatedLayerImage,
                    updatedMaskImage,
                    psd_sku,
                    deleteImages,
                    descriptionHtml
                ) => {
                    if (!selectedItem) return
                    setUploading(true);

                    const newLayers = updatedLayerImage.filter(
                        (item: any) => typeof item.image === 'object',
                    )
                    const oldLyers = updatedLayerImage.filter(
                        (item: any) => typeof item.image === 'string',
                    ) as Array<string>

                    let newUploadedLayers: Array<{ action: string, image: string, comment: string }> = []
                    if (newLayers.length !== 0) {
                        //todo
                        const layer = await opStore.uploadImages(newLayers.map((item: any) => item.image), () => { })

                        layer.map(async (item: any, index: number) => {

                            newUploadedLayers.push({
                                action: 'add',
                                image: item.url,
                                comment: newLayers[index].comment
                            })

                        })

                    }


                    const newMasks = updatedMaskImage.filter(
                        (item: any) => typeof item.image === 'object',
                    )

                    let newUploadedMasks: Array<{ action: string, image: string, comment: string }> = []
                    if (newMasks.length !== 0) {
                        //todo
                        const mask = await opStore.uploadImages(newMasks.map((item: any) => item.image), () => { })

                        mask.map(async (item: any, index: number) => {


                            newUploadedMasks.push({
                                action: 'add',
                                image: item.url,
                                comment: 'psd clipping mask ' + newMasks[index].comment
                            })

                        })

                    }


                    const layersToUpdate = [
                        ...oldLyers,

                    ] as string[]


                    const newCovers = updatedCoverImage.filter(
                        (image) => typeof image === 'object',
                    ) as Array<File>
                    const oldCovers = updatedCoverImage.filter(
                        (image) => typeof image === 'string',
                    ) as Array<string>
                    let newUploadedCovers: Array<PreviewImageItem> = []
                    if (newCovers.length !== 0) {
                        newUploadedCovers = await opStore.uploadImages(newCovers, () => { })
                    }
                    const coversToUpdate = [
                        ...oldCovers,
                        ...newUploadedCovers.map((nuc) => nuc.url),
                    ] as string[]

                    const newEditables = updatedEditableImage.filter(
                        (image) => typeof image === 'object',
                    ) as Array<File>

                    let newUploadedEditables: Array<PreviewImageItem> = []
                    if (newEditables.length !== 0) {
                        newUploadedEditables = await opStore.uploadImages(
                            newEditables,
                            () => { },
                        )
                    }
                    const newRawElements: Array<RawImageElemet> = newUploadedEditables.map(nue => {
                        return {
                            id: 0,
                            image: nue.url!,
                            comment: '',
                            action: 'add'
                        }
                    })

                    const oldEditables = updatedEditableImage.filter(
                        (image) => typeof image === 'string',
                    ) as Array<string>

                    const oldRawElements: RawImageElemet[] = selectedItem.rawImageURLs.map((riu) => {
                        const keep = oldEditables.find((oe) => oe === riu.image) !== undefined

                        return {
                            ...riu,
                            action: keep ? 'keep' : 'delete'
                        }
                    })
                    const editableImagesToUpdate = [...oldRawElements, ...newRawElements, ...deleteImages, ...newUploadedMasks, ...newUploadedLayers];

                    const newData = {
                        ...selectedItem,
                        name: title,
                        pairPrice: price,
                        shippingOptions,
                        weight,
                        volume,
                        descShorten: desc,
                        coverImages: coversToUpdate,
                        rawImageURLs: editableImagesToUpdate,
                        // @ts-ignore
                        psdLayerImages: layersToUpdate,
                        psd_sku,
                        description_html: descriptionHtml
                    }

                    const result = await opStore.updateOriginalProduct(
                        // @ts-ignore
                        newData,
                        authStore.token,
                    )
                    setUploading(false);
                    if (result) {
                        setShowEditProduct(false)
                        refreshProducts(opStore.originalProductsCurrentPage)
                    }
                }}
            />
        </OriginalProductProvider>
    )
})

export default Catalog
