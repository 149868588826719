
/**
 *
 * @param str 字符串
 * @returns 获取字符串中的数字
 */
export function getSize(str: string) {
    const result = str.replace(/[a-zA-Z]+\s*/g, '');
    return result
}

/**
 *
 * @param str 字符串
 * @returns 获取字符串中的英文字母
 */
export function getLayer(str: string) {
    const englishWords = str.match(/[a-zA-Z]+/g);  // 获取所有英文字符，不包括空格
    return englishWords?.join(' ')
}

// css样式
export const styles: { [key: string]: Record<string, string> } = {
    commonBtn: {
        borderRadius: '18px',
        color: '#000000',
    },
    activeBtn: {
        borderRadius: '18px',
        background: '#DBF2FA',
        color: '#1164A9',
        fontWeight: 'bold',
    },
};

export const btnIconStyle = {
    width: '16px',
    height: '10px',
    cursor: 'pointer',
}

export const canvasStyleObj: { [key: string]: Record<string, string> } = {
    bookList_body: {
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center'
    },
    canvasBox: {
        display: 'none',
        position: 'relative',
    },
    active: {
        display: 'block'
    },
    canvas: {
    }
}

/**
 *
 * @param base64Image base64图片
 * @param targetWidth 目标宽度
 * @param targetHeight 目标高度
 * @returns 目标图片
 */
export function resizeBase64Image(base64Image: string, targetWidth: number, targetHeight: number) {
    return new Promise((resolve, reject) => {
        // Create an image element
        const img = new Image();
        img.src = base64Image;

        img.onload = function () {
            // Create a canvas element
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');

            // Set the canvas dimensions to the target size
            canvas.width = targetWidth;
            canvas.height = targetHeight;

            // Draw the image onto the canvas, resizing it to the target dimensions
            context!.drawImage(img, 0, 0, targetWidth, targetHeight);

            // Convert the canvas content back to a base64 string
            const resizedBase64Image = canvas.toDataURL();

            resolve(resizedBase64Image);
        };

        img.onerror = function (error) {
            reject(error);
        };
    });
}

/**
 *
 * @param url 图片地址
 * @returns 图片
 */
export function get1600Base64Image(url: string) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = url;

        img.onload = () => {
            // 创建一个Canvas对象
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            if (!ctx) return;

            // 根据新尺寸设置Canvas的宽高
            const scaleFactor = 1600 / Math.max(img.width, img.height);
            canvas.width = img.width * scaleFactor;
            canvas.height = img.height * scaleFactor;

            // 绘制缩放后的图像
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

            // 将Canvas内容转为Base64
            const resizedBase64 = canvas.toDataURL('image/png');

            resolve(resizedBase64)
        }
    })
}

/**
 *
 * @param obj fabric object
 * @returns 是否是文本对象
 */
export function isTextObject(obj: fabric.Object): obj is fabric.Text | fabric.Textbox {
    return obj.type === 'text' || obj.type === 'textbox';
}

export const title = {
    fontSize: '22px',
    fontWeight: 'bold',
    color: '#373737',
    marginTop: '20px',
    width: '80%',
    display: 'flex',
    justifyContent: 'flex-start'
}

export const pixelsToInches = (pixels: number) => {
    const dpi = window.devicePixelRatio * 96; // 假设 DPI 为 96
    return pixels / dpi;
};

export function capitalizeFirstLetter(str: string) {
    if (!str) return str; // 如果字符串为空，直接返回
    return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 *
 * @param base64List base64 图片编码
 * @returns 下载单个1600px图片
 */
export function downlandImages(base64List: string) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = base64List;

        img.onload = () => {
            // 创建一个Canvas对象
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            if (!ctx) return;

            // 根据新尺寸设置Canvas的宽高
            const scaleFactor = 1600 / Math.max(img.width, img.height);
            canvas.width = img.width * scaleFactor;
            canvas.height = img.height * scaleFactor;

            // 绘制缩放后的图像
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

            // 将Canvas内容转为Base64
            const resizedBase64 = canvas.toDataURL('image/png');

            // 创建一个下载链接
            const link = document.createElement('a');
            link.href = resizedBase64;
            link.download = 'resized-image.png';

            // 触发下载
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            resolve(true)
        };

        img.onerror = (err) => {
            console.error('Failed to load image:', err);
            reject('Failed to load image:' + err)
        };
    })
}