import { Box } from '@fower/react';
import { styled } from '@fower/styled';
import styles from './LandingSection.module.css';

const KeyPoints = () => {
  const StyledImage = styled('img', {
    objectFit: 'cover',
  });
  return (
    <Box w='100%' toCenter column bgGray100 p5> 
      <Box className={styles.keypointsTitle} hidden display--md="flex">Why Choose Us</Box>
      <Box className={styles.keypointsTitleSmall} hidden--md>Why Choose Us</Box>
      <Box bg='#6E8FA0' w11 h1 mt2></Box>
      <Box column row--xl mt10>
          <Box column w='336px' toCenter spaceY3 mr10 ml10>
              <StyledImage src='/icons/icon_source.png' w='128px' h='128px' alt='source'/>
              <Box className={styles.keypointReasonTitle}>From the Source</Box>
              <Box className={styles.keypointReasonContent} textCenter>Purchasing directly from a manufacturer means cutting out the middleman to get unparalleled prices</Box>
          </Box>
          <Box column w='336px' toCenter spaceY3 mr10 ml10>
              <StyledImage src='/icons/icon_creative.png' w='128px' h='128px' alt='creative'/>
              <Box className={styles.keypointReasonTitle}>Creative Freedom</Box>
              <Box className={styles.keypointReasonContent} textCenter>You have a wide range of merchandise and be able to showcase your brand and identity to your fans</Box>
          </Box>
          <Box column w='336px' toCenter spaceY3 mr10 ml10>
              <StyledImage src='/icons/icon_quality.png' w='128px' h='128px' alt='quality'/>
              <Box className={styles.keypointReasonTitle}>Audited Quality</Box>
              <Box className={styles.keypointReasonContent} textCenter>We use the same factories as top brands to ensure the quality and run a 23-point audit before your product shipped </Box>
          </Box>
      </Box>
    </Box>
  );
};

export default KeyPoints;
