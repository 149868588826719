import { Box } from '@fower/react'
import {
    Grid,
    ImageList,
    ImageListItem,
    Skeleton,
    Typography,
} from '@mui/material'
import { FC, useEffect, useState } from 'react'
import styles from './ProductList.module.css'
import StraightenIcon from '@mui/icons-material/Straighten'
import { ProductItemVO } from '../../TypeDeclare'
import { observer } from 'mobx-react-lite'
import { styled } from '@fower/styled'
type ItemViewProps = {
    data: ProductItemVO
    onClick: (item: ProductItemVO) => void
}
const StyledImage = styled('img')
const ItemView: FC<ItemViewProps> = ({ data, onClick }) => {
    const { name, images, sizeRange, pairPrize, descShorten, coverImages } = data

    const imagesToShow =
        coverImages.length >= 2 ? coverImages : images.map((img) => img.url)
    if (imagesToShow.length < 2) {
        imagesToShow.push(images[0].url!)
    }
    const [show2nd, setShow2nd] = useState<boolean>(false)
    return (
        <Box
            minW="280px"
            h="480px"
            border="solid 1px rgba(0,0,0,0.2)"
            column
            overflowHidden
            bg={'#F4F6F7'}
            relative
            cursorPointer
            onClick={() => {
                onClick(data)
            }}
        >
            <Box w="100%" h="296px" relative top0 left0 overflowHidden>
                <StyledImage
                    src={imagesToShow[0]}
                    alt={name}
                    className={`${styles.itemImage} ${show2nd ? styles.fadeOut : styles.fadeIn
                        }`}
                    style={{
                        objectFit: 'cover',
                        width: '100%',
                        height: '100%',
                    }}
                    absolute
                    top0
                    left0
                />
                <StyledImage
                    src={imagesToShow[1]}
                    alt={name}
                    className={`${styles.itemImage} ${styles.popUpFade}`}
                    style={{
                        objectFit: 'cover',
                        width: '100%',
                        height: '100%',
                    }}
                    absolute
                    top0
                    left0
                    onMouseEnter={() => {
                        setShow2nd(true)
                    }}
                    onMouseLeave={() => {
                        setShow2nd(false)
                    }}
                />
            </Box>
            <Typography
                variant="h4"
                sx={{
                    m: 1,
                }}
            >
                {name}
            </Typography>
            <Box textXS ml4 mr4 mb2 gray600 h12 className={styles.multiLine}>
                {descShorten}
            </Box>
            <Box row toCenterY ml4>
                <StraightenIcon fontSize="small" />
                <Box ml1 textSM>{`${sizeRange}`}</Box>
            </Box>
            <Box ml4 mt3 color="#4384D6" fontBold>
                ${pairPrize}
            </Box>
        </Box>
    )
}

export type ItemListProps = {
    list: ProductItemVO[]
    onSelect: (item: ProductItemVO) => void
}
const ItemList: FC<ItemListProps> = observer(({ list, onSelect }) => {
    const getCV = () => {
        if (global.innerWidth > 1075) return 3
        if (global.innerWidth > 640) return 2
        return 1
    }
    const [columns, setColumns] = useState<number>(getCV())
    useEffect(() => {
        global.addEventListener('resize', (e) => {
            setColumns(getCV())
        })
    }, [])

    return (
        <ImageList
            sx={{
                width: '100%',
                height: '100%',
                marginTop: '0px',
                padding: '5px',
            }}
            variant="quilted"
            cols={columns}
            rowHeight={500}
        >
            {list.map((item, index) => {
                if (item.id === '-1') {
                    return (
                        <ImageListItem key={index.toString()}>
                            <Grid key={index.toString()} height={480} width={'100%'}>
                                <Skeleton
                                    variant="rectangular"
                                    animation="wave"
                                    width={'100%'}
                                    height={296}
                                />
                                <Skeleton
                                    variant="rectangular"
                                    animation="wave"
                                    width={'100%'}
                                    height={184}
                                    sx={{
                                        bgcolor: 'grey.200',
                                    }}
                                />
                            </Grid>
                        </ImageListItem>
                    )
                }
                return (
                    <ImageListItem key={item.id}>
                        <ItemView
                            data={item}
                            onClick={(data) => {
                                onSelect(data)
                            }}
                        />
                    </ImageListItem>
                )
            })}
        </ImageList>
    )
})
export default ItemList
