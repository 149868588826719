import { FC, useRef } from "react";
import '@wangeditor/editor/dist/css/style.css' // 引入 css

import React, { useState, useEffect } from 'react'
import { Editor, Toolbar } from '@wangeditor/editor-for-react'
import { IDomEditor, IEditorConfig, IToolbarConfig } from '@wangeditor/editor'
import { DomEditor } from '@wangeditor/editor'
import { useOriginalProductStore } from "../../store/useOriginalProductStore";
interface QuillEditorProps {
    onGetHtml: (data: string) => void
    html: string
}

const QuillEditor: FC<QuillEditorProps> = ({ onGetHtml, html }) => {
    const opStore = useOriginalProductStore()
    // editor 实例
    const [editor, setEditor] = useState<IDomEditor | null>(null)   // TS 语法

    async function uploadImages(
        files: Array<File | undefined>,
    ) {
        const result = await opStore.uploadImages(
            files as File[],
            (_) => { },
        )
        return result;
    }


    // 工具栏配置
    const toolbarConfig: Partial<IToolbarConfig> = {
        excludeKeys: ['uploadVideo'],


    }  // TS 语法

    // 编辑器配置
    const editorConfig: Partial<IEditorConfig> = {    // TS 语法
        // const editorConfig = {                         // JS 语法
        placeholder: '请输入内容...',
        MENU_CONF: {
            // 自定义图片上传
            uploadImage: {
                // 自定义上传
                async customUpload(file: File, insertFn: any) {  // TS 语法
                    const res = await uploadImages([file])

                    const imgUrl = res[0].url; // 获取服务器返回的图片 URL
                    insertFn(imgUrl); // 使用 insertFn 方法插入图片
                },
            },
        },
    }

    // 及时销毁 editor ，重要！
    useEffect(() => {

        const toolbar = DomEditor.getToolbar(editor!)
        if (toolbar) {
            const curToolbarConfig = toolbar!.getConfig()
            console.log(curToolbarConfig.toolbarKeys) // 当前菜单排序和分组
        }

        return () => {
            if (editor == null) return
            editor.destroy()
            setEditor(null)
        }
    }, [editor])

    return (
        <>
            <div style={{ border: '1px solid #ccc', zIndex: 100, width: '100%', minHeight: '200px' }}>
                <Toolbar
                    editor={editor}
                    defaultConfig={toolbarConfig}
                    mode="default"
                    style={{ borderBottom: '1px solid #ccc' }}
                />
                <Editor
                    defaultConfig={editorConfig}
                    value={html}
                    onCreated={setEditor}
                    onChange={editor => {
                        onGetHtml(editor.getHtml())
                    }}
                    mode="default"
                    style={{ height: '500px', overflowY: 'hidden' }}
                />
            </div>

        </>
    )
}

export default QuillEditor;