import { getCTKey } from '../../../common/StringUtils';
import {
    CategoryInfo,
    ColorSetVO,
    CostVO,
    EditableFace,
    MyProductItemVO,
    PackageInfoVO,
    PreviewImageItem,
    ProductItemVO,
    ShippingFeeDetailVO,
    ShippingMethod,
    TagInfo,
    Volume,
    ZeroVolume,
} from '../../../TypeDeclare';
import { EditorStore } from '../../editor/store/editor/EditorStore';
import { ProductStore } from '../store/ProductStore';
import offsets from '../store/offsets.json';
import { isEmptyObject } from '../../../common/Object';
import { doGet, doPost } from '../../../common/commonService';

const postFormData = async (url: string, params: any, token?: string) => {
    const formData = new FormData();
    for (const key in params) {
        if (Object.prototype.hasOwnProperty.call(params, key)) {
            const element: string = params[key];
            formData.append(key, element);
        }
    }
    formData.append('ct_campaign', getCTKey()!);

    const result = await doPost(url, formData, token);
    return result;
};

//加载产品列表
export const getProducts = async (
    info: CategoryInfo,
    page: number = 1,
    isReadyToShip: boolean
): Promise<any> => {
    let url = `https://api.pairdrop.com/api/products/?customizable=1&page=${page}&customtee_web=1&ready_to_ship=${isReadyToShip ? 1 : 0
        }`;
    if (info.category !== 0 && info.tag === 0) {
        url = `https://api.pairdrop.com/api/products/?customizable=1&category=${info.category
            }&page=${page}&customtee_web=1&ready_to_ship=${isReadyToShip ? 1 : 0}`;
    }
    if (info.category === 0 && info.tag !== 0) {
        url = `https://api.pairdrop.com/api/products/?customizable=1&tags=[${info.tag
            }]&page=${page}&customtee_web=1&ready_to_ship=${isReadyToShip ? 1 : 0}`;
    }
    return new Promise(async (resolve) => {
        const respose = await fetch(url);
        const result = await respose.json();
        resolve(result);
    });
};
export const getProductByKeyword = async (
    keyword: string,
    page: number = 1
): Promise<any> => {
    let url = `https://api.pairdrop.com/api/products/?customizable=1&page=${page}$keyword=${keyword}&customtee_web=1`;
    return new Promise(async (resolve) => {
        const respose = await fetch(url);
        const result = await respose.json();
        resolve(result);
    });
};
//加载单个产品详细信息
export const getProductByID = async (id: string): Promise<any> => {
    const url = `https://api.pairdrop.com/api/customteeproducts/${id}/`;
    return new Promise(async (resolve) => {
        const respose = await fetch(url);
        const result = await respose.json();
        resolve(result);
    });
};
//以base64数据格式加载图片
export const loadBase64Image = async (url: string): Promise<string> => {
    return new Promise((resolve, reject) => {
        var xhr = new XMLHttpRequest();
        xhr.open('get', url, true);
        xhr.responseType = 'blob';
        xhr.onload = function () {
            if (this.status === 200) {
                var blob = this.response;
                var fileReader = new FileReader();
                fileReader.onloadend = function (e) {
                    var result = e.target!.result as string;
                    resolve(result);
                };
                fileReader.readAsDataURL(blob);
            }
        };
        xhr.onerror = function () {
            reject();
        };
        xhr.send();
    });
};
//创建订制商品
export const createCustomTeeProduct = async (
    editorStore: EditorStore,
    productStore: ProductStore,
    uploadedImages: PreviewImageItem[],
    fullColorSet: any[],
    token?: string
): Promise<any> => {
    const product: ProductItemVO = editorStore.product!;
    const url = `https://api.pairdrop.com/api/createCustomTeeProduct/`;
    return new Promise(async (resolve) => {
        const formData = new FormData();
        formData.append('parent_id', product.id);
        formData.append('color_id', editorStore.templateColor.id);
        formData.append('size_id', productStore.size.id);
        formData.append('customtee_web', '1');
        formData.append('full_color_set', JSON.stringify(fullColorSet));
        uploadedImages.forEach((image) => {
            formData.append(image.alias, image.url!);
        });
        const result = await doPost(url, formData, token);
        resolve(result);
    });
};

//创建订单
export const createPaymentAPI = async (
    params: any,
    token: string
): Promise<any> => {
    const url = `https://www.customtee.co/api/createPaypalOrderV2/`;
    return postFormData(url, params, token);
};

//核销订单
export const capturePaymentAPI = async (
    params: any,
    token: string
): Promise<any> => {
    const url = `https://www.customtee.co/api/capturePaypalOrderV2/`;
    return postFormData(url, params, token);
};

export const getPromotion = async (params: any): Promise<any> => {
    const url = `https://www.customtee.co/api/orderAmountCalculation/`;
    return postFormData(url, params);
};

export const getShippingFeeByQTY = async (
    productId: string,
    token: string,
    shippingMethod: string,
    quantity: number
): Promise<any> => {
    //45613
    const url = `https://www.customtee.co/api/customteeProductShippingFee/${productId}/`;
    return doGet(
        url,
        { shipping_method: encodeURIComponent(shippingMethod), quantity },
        token
    );
};

export const getFeaturedProducts = async (): Promise<any> => {
    const url = `https://www.customtee.co/api/customteeLandingPageProducts/`;
    return doGet(url, null, undefined);
};

export const parseProduct = (
    element: any,
    imageList?: any[]
): ProductItemVO => {
    const faces = ['front', 'back'];
    const image_urls = imageList || (element['image_urls'] as any[]);
    const offset = offsets.list.find((o) => {
        return o.id === String(element['id']);
    });
    const images: EditableFace[] = image_urls.map((item: any, index: number) => {
        return {
            url: String(item['image']),
            face: faces[index],
            xOff: offset ? offset.xOff : 0,
            yOff: offset ? offset.yOff : 0,
            pW: offset ? offset.pW : 0.5,
            pH: offset ? offset.pH : 0.5,
        };
    });
    const desc = String(element['short_description']);
    const shopURL = `${String(element['customtee_product_url'])}`;
    const fullColorSet = element['full_color_set']
        ? (element['full_color_set'] as ColorSetVO[])
        : [];
    const sms: ShippingMethod[] = parseShippingMethods(element);
    const vo = {
        id: String(element['id']),
        name: String(element['name']),
        category: {
            id: String(element['category']),
            parentName: String(element['category']),
            category: 0,
            tag: 0,
        },
        type: String(element['category']),
        images,
        desc: desc,
        descShorten: `${desc.substring(0, 75)} ...`,
        sizeRange: String(element['size_range']),
        price: String(element['default_price']),
        daysInProduction: Number(element['days_in_production']),
        sizeTableImage: element['description_image'],
        colorId: '2',
        shippingFee: element['shipping_fee'],
        isFullPrint: Boolean(element['is_fullprint']),
        fullColorSet: fullColorSet,
        customteeProductUrl: shopURL,
        costPrice: element['cost_price'],
        productId: element['product_id'],
        defaultPrice: element['default_price'],
        pairPrize: element['pair_price'],
        originalPrize: element['original_price'],
        shippingMethods: sms,
        weight: parseWeight(element),
        volume: parseVolume(element),
        coverImages: getCoverImages(element),
        readyToShip: element['ready_to_ship'] === 1,
        tags: element['tag_info'] ? element['tag_info'].map((e: any) => e as TagInfo) : [],
        psd_layer_images: element['psd_layer_images'],
        psd_sku: element['psd_sku'],
        psd_clipping_masks: element['psd_clipping_masks'],
        description_html: element['description_html'] || '',
    };
    return vo;
};

export const parseFeaturedProduct = (element: any): ProductItemVO => {
    const faces = ['front', 'back'];
    const offset = offsets.list.find((o) => {
        return o.id === String(element['id']);
    });
    const images: EditableFace[] = [
        {
            url: element['cover_image'],
            face: faces[0],
            xOff: offset ? offset.xOff : 0,
            yOff: offset ? offset.yOff : 0,
            pW: offset ? offset.pW : 0.3,
            pH: offset ? offset.pH : 0.5,
        },
    ];
    const desc = String(element['short_description']);
    const shopURL = `${String(element['customtee_product_url'])}`;
    const fullColorSet = element['full_color_set']
        ? (element['full_color_set'] as ColorSetVO[])
        : [];
    const sms: ShippingMethod[] = parseShippingMethods(element);
    const vo = {
        id: String(element['id']),
        name: String(element['name']),
        category: {
            id: String(element['category']),
            parentName: String(element['category']),
            category: 0,
            tag: 0,
        },
        type: String(element['category']),
        images,
        desc: desc,
        descShorten: `${desc.substring(0, 75)} ...`,
        sizeRange: String(element['size_range']),
        price: String(element['default_price']),
        daysInProduction: Number(element['days_in_production']),
        sizeTableImage: element['description_image'],
        colorId: '2',
        shippingFee: element['shipping_fee'],
        isFullPrint: Boolean(element['is_fullprint']),
        fullColorSet: fullColorSet,
        customteeProductUrl: shopURL,
        costPrice: element['cost_price'],
        productId: element['product_id'],
        defaultPrice: element['default_price'],
        pairPrize: element['pair_price'],
        originalPrize: element['original_price'],
        shippingMethods: sms,
        weight: parseWeight(element),
        volume: parseVolume(element),
        coverImages: getCoverImages(element),
        readyToShip: element['ready_to_ship'] === 1,
        tags: []
    };
    return vo;
};

export const parseMyProduct = (
    element: any,
    imageList?: any[]
): MyProductItemVO => {
    const commonProduct: ProductItemVO = parseProduct(element, imageList);
    return {
        ...commonProduct,
        onSaleCustomtee: element['sale_on_customtee'],
        onSaleShopify: element['sale_on_shopify'],
    };
};

export const parseShopifyProduct = (element: any): ProductItemVO => {
    const faces = ['front', 'back'];
    const image_urls = [element['cover_image']];
    const offset = offsets.list.find((o) => {
        return o.id === String(element['id']);
    });
    const images: EditableFace[] = image_urls.map((item: any, index: number) => {
        return {
            url: item,
            face: faces[index],
            xOff: offset ? offset.xOff : 0,
            yOff: offset ? offset.yOff : 0,
            pW: offset ? offset.pW : 0.5,
            pH: offset ? offset.pH : 0.5,
        };
    });
    const desc = String(element['short_description']);
    const shopURL = `${String(element['customtee_product_url'])}`;
    const fullColorSet = element['full_color_set']
        ? (element['full_color_set'] as ColorSetVO[])
        : [];

    const vo = {
        id: String(element['id']),
        name: String(element['name']),
        category: {
            id: String(element['category']),
            parentName: String(element['category']),
            category: 0,
            tag: 0,
        },
        type: String(element['category']),
        images,
        desc: desc,
        descShorten: `${desc.substring(0, 75)} ...`,
        sizeRange: String(element['size_range']),
        price: String(element['default_price']),
        daysInProduction: Number(element['days_in_production']),
        sizeTableImage: element['description_image'],
        colorId: '2',
        shippingFee: element['shipping_fee'],
        isFullPrint: Boolean(element['is_fullprint']),
        fullColorSet: fullColorSet,
        customteeProductUrl: shopURL,
        costPrice: element['cost_price'],
        pairPrize: element['pair_price'],
        productId: element['product_id'],
        shippingMethods: [],
        weight: parseWeight(element),
        volume: parseVolume(element),
        coverImages: getCoverImages(element),
        readyToShip: element['ready_to_ship'] === 1,
        tags: []
    };

    return vo;
};
const SMRANK: { [kye: string]: number } = {
    'Economy Shipping': 0,
    'Standard Shipping': 1,
    'Express Shipping': 2,
};

const DEFAULT_SMS: ShippingMethod[] = [
    {
        id: 'Economy Shipping',
        fee: 8,
        weightComment: '6 - 12 Days',
    },
    {
        id: 'Standard Shipping',
        fee: 12,
        weightComment: '5 - 8 Days',
    },
    {
        id: 'Express Shipping',
        fee: 23,
        weightComment: '3 - 5 Days',
    },
];

export const parseShippingMethods = (element: any): ShippingMethod[] => {
    if (isEmptyObject(element['shipping_options'])) {
        return DEFAULT_SMS;
    }
    const sms: ShippingMethod[] = [];

    for (const key in element['shipping_options']) {
        if (
            Object.prototype.hasOwnProperty.call(element['shipping_options'], key)
        ) {
            const so = element['shipping_options'][key];
            sms.push({
                id: key,
                fee: Number(so['shipping_fee']),
                weightComment: so['comment'],
            });
        }
    }
    sms.sort((a, b) => {
        return SMRANK[a.id] > SMRANK[b.id] ? 1 : -1;
    });
    return sms;
};
export const parseWeight = (element: any): number => {
    if (element['weight'] !== undefined) {
        return Number(element['weight']);
    }
    return 0;
};
export const parseVolume = (element: any): Volume => {
    if (element['volume'] !== undefined) {
        if (typeof element['volume'] === 'string') {
            const vObj = JSON.parse(element['volume']);
            return {
                width: Number(vObj['width']),
                height: Number(vObj['height']),
                length: Number(vObj['length']),
            };
        } else {
            if (isEmptyObject(element['volume'])) {
                return ZeroVolume;
            } else {
                const vObj = element['volume'];
                return {
                    width: Number(vObj['width']),
                    height: Number(vObj['height']),
                    length: Number(vObj['length']),
                };
            }
        }
    }
    return {
        width: 0,
        height: 0,
        length: 0,
    };
};
const parseCostVOs = (obj: any): Array<CostVO> => {
    const tsc: Array<CostVO> = [];
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const element = obj[key];
            tsc.push({
                shippingMethodId: key,
                cost: Number(element),
            });
        }
    }
    return tsc;
};
export const parseShippingFeeDetail = (data: any): ShippingFeeDetailVO => {
    const tscObj = data['total_shipping_cost'];
    const totalShippingCost: Array<CostVO> = parseCostVOs(tscObj);
    const boxList = data['box_list'] as Array<any>;
    const boxPriceList = data['box_price_list'] as Array<any>;
    const packageList: Array<PackageInfoVO> = boxList.map((quantity, i) => {
        const content = boxPriceList[i];
        const costList = parseCostVOs(content);
        return {
            quantity: Number(quantity),
            costList,
        };
    });
    return {
        totalShippingCost,
        packageList,
    };
};

export const getCoverImages = (element: any) => {
    if (Array.isArray(element['cover_images'])) {
        const cis = element['cover_images'] as string[]
        if (cis.length > 1) {
            return cis
        }
    }
    if (element['image_urls'] === undefined) return []
    if (element['image_urls'].length < 1) return []
    return [element['image_urls'][0]['image']]
};
