import { FC } from "react"
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import CheckIcon from '@mui/icons-material/Check';
import { Box } from "@fower/react";

interface SizePickerProps {
    sizeOptions: { id: string, value: string }[]
    getSizeHandle: (val: string | number) => void
    defaultChecked: number | string
}

const SizePicker: FC<SizePickerProps> = ({
    sizeOptions, getSizeHandle, defaultChecked
}) => {
    return (
        <FormControl
            style={{ marginTop: '10px' }}
        >
            <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                defaultValue={defaultChecked}
                onChange={(event) => {
                    getSizeHandle(event.target.value)
                }}
            >
                {
                    sizeOptions.map(item => {
                        return (
                            <FormControlLabel
                                key={item.id}
                                value={item.id}
                                style={{ marginLeft: '0' }}
                                labelPlacement="start"
                                control={<Radio
                                    icon={
                                        <Box
                                            w="20px"
                                            h="20px"
                                            flex
                                            toCenter
                                            style={{
                                                borderRadius: '50%',
                                                border: '1px solid #707070',
                                                background: 'transparent',
                                                marginRight: '16px'
                                            }}
                                        ></Box>
                                    }
                                    checkedIcon={
                                        <Box
                                            w="20px"
                                            h="20px"
                                            flex
                                            toCenter
                                            style={{
                                                borderRadius: '50%',
                                                border: '1px solid #707070',
                                                background: '#518DD9',
                                                marginRight: '16px'
                                            }}
                                        >
                                            <CheckIcon
                                                style={{
                                                    color: '#FFFFFF',
                                                    fontSize: '16px'
                                                }}
                                            />
                                        </Box>
                                    }
                                />}
                                label={item.value}
                            />
                        )
                    })
                }


            </RadioGroup>
        </FormControl>
    )
}

export default SizePicker