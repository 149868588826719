import { Box } from '@fower/react'
import { styled } from '@fower/styled'
import { FC } from 'react'
import { StyledImage } from '../../TypeDeclare'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
const StyledIframe = styled('iframe')
const IntroVideo: FC = () => {
    const navigator = useNavigate()
    return (
        <Box
            flexDirection={['column', 'column', 'column', 'column', 'row']}
            toCenterY
            toCenter
            spaceX={['60px', '0px', '0px', '0px', '0px']}
        >
            <Box column p5 toCenterX spaceY10>
                <Box row toCenterY spaceX5>
                    <Box row toCenterY>
                        <StyledImage src="/icons/logo_macaw.png" alt="logo" w10 h10 />
                        <Box text3XL italic fontSemibold>
                            Custom Tee
                        </Box>
                    </Box>
                    <StyledImage src="/icons/unchecked.png" alt="logo" w6 h6 />
                    <StyledImage src="/icons/shopify_logo_whitebg.svg" alt="logo" h10 />
                </Box>
                <Box text2XL italic fontSemibold>
                    Custom Tee Integration for Shopify
                </Box>
                <Button
                    variant="contained"
                    sx={{
                        width: '264px',
                        height: '48px',
                        background: '#4384D6'
                    }}
                    onClick={() => {
                        navigator('/dashboard?section=Stores')
                    }}
                >
                    Connect
                </Button>
            </Box>
            <StyledIframe
                mt16
                w--lg="640px"
                w--md="800px"
                w="100%"
                h--lg="360px"
                h--md="450px"
                h="360px"
                src="https://www.youtube.com/embed/lhA5b97sK-8"
                title="Custom Tee Integration for Shopify"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></StyledIframe>
        </Box>
    )
}
export default IntroVideo
