import { FC } from 'react'
import { Box } from '@fower/react'
import { Typography } from '@mui/material'
import { toContactUs } from './common-functions'

export type ContactUSProps = {
    onAction: () => void
}
const ContactUS: FC<ContactUSProps> = ({ onAction }) => {
    return (
        <Box cursorPointer onClick={() => {
            onAction();
            toContactUs()
        }}>
            <Typography variant="h3" color={'#4384D6'}>
                Contact Us
            </Typography>
        </Box>
    )
}
export default ContactUS
